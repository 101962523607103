body {
    min-width: 300px;
}

h1, h2, h3 h4, h5 {
    font-family: $font_r;
}

p, a, span, ul, li {
    font-family: $font_m;
}

//Header Корзина
.item.pull-left.is-empty {
    font-family: $font_m !important;
    font-size: 11px !important;
    padding: 15px 0 !important;
    margin: 0 !important;
}

// Красные линии сайта
.header-bottom-line {
    display: none !important;
}