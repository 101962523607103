@mixin all-trans-ease($time) {
    -webkit-transition: all $time ease;
    -moz-transition: all $time ease;
    -ms-transition: all $time ease;
    -o-transition: all $time ease;
    transition: all $time ease;
}

@mixin shadow-filter {
    &::before {
        content: '';
        position: absolute;
        top: -51px;
        right: 0;
        left: 0;
        bottom: 0;
        border: 1px solid $border-color;
        border-top: none;
        z-index: -1;
        box-shadow: 0 0 15px 0 rgba($black, .25);
        opacity: 0;

        @include all-trans-ease(.5s);
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        border-right: 1px solid $border-color;
        border-left: 1px solid $border-color;
        z-index: -1;
    }

    &.active {
        &::before {
            opacity: 1;
        }
    }
}

@mixin arrow-toggle {
    &::after {
        font-family: Flaticon;
        content: "\f104";
        font-size: 20px;
        font-weight: 600;
        display: inline-block;
        position: absolute;
        right: 20px;
        top: 10px;
        cursor: pointer;

        @include all-trans-ease(.5s);
    }

    &.active {
        &::after {
            transform: rotate(-180deg);
        }
    }
}