.product-section {
    width: 100%;
    padding: 30px 0;
    box-sizing: border-box;
}

.row.category_list_product,
.related-products-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 auto;
}

.category_list_product article,
.related-products-list article {
    width: 25%;
    min-height: initial;
    height: auto;
    padding: 0;
    padding-right: 20px;
    margin-bottom: 30px;

    @include max-screen(1200px) {
        width: calc(100% / 3);
    }

    @include max-screen(992px) {
        width: 50%;
    }

    @include max-screen(576px) {
        width: 70%;
        padding: 0 !important;
        margin: 0 auto 50px;
    }

    @include max-screen(480px) {
        width: 80%;
    }

    &:nth-child(4n) {
        padding-right: 0;

        @include max-screen(1200px) {
            padding-right: 20px;
        }
    }

    &:nth-child(3n) {
        @include max-screen(1200px) {
            padding-right: 0;
        }

        @include max-screen(992px) {
            padding-right: 20px;
        }
    }

    &:nth-child(2n) {
        @include max-screen(992px) {
            padding-right: 0;
        }
    }
}

.category_list_product .product,
.related-products-list .product {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    max-width: 100%;
    min-height: unset;
    height: unset;
    border: none;
    background-color: transparent;

    .action_item_more {
        opacity: 0;
        color: $white;
        position: absolute;
        height: 400px;
        width: 100%;
        top: 0;
        left: 0;
        z-index: -1;

        background: -webkit-linear-gradient(135deg, rgba($blue, 0.93), rgba($gold, 0.93));
        background: -o-linear-gradient(135deg, rgba($blue, 0.93), rgba($gold, 0.93));
        background: linear-gradient(-45deg, rgba($blue, 0.93), rgba($gold, 0.93));

        -webkit-transition: all .5s ease-out;
        -o-transition: all .5s ease-out;
        transition: all .5s ease-out;

        @include max-screen(1024px) {
            display: none;
        }

        &__inner {
            position: absolute;
            top: 50%;
            left: 50%;
        }

        &__text {
            position: inherit;
            display: inline-block;
            text-transform: uppercase;
            font-family: $font_r;

            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

            a {
                font-size: 17px;
                display: block;
                color: $white;
                text-decoration: none;
                margin: 20px 0;
                opacity: 0;

                -webkit-transition: all .5s ease;
                -o-transition: all .5s ease;
                transition: all .5s ease;
            }

            &::before,
            &::after {
                content: '';
                display: block;
                position: absolute;
                height: 1px;
                right: 50%;
                left: 50%;
                background: $white-gray;

                -webkit-transition: all .5s ease;
                -o-transition: all .5s ease;
                transition: all .5s ease;
            }
        }
    }

    .figure-hover-overlay {
        max-width: 100%;
        height: 400px;
        margin: 0;

        .img-responsive {
            height: inherit;
            object-fit: cover;
            object-position: 50% 0;
            margin: auto;

            + .bar {
                display: none;
            }
        }
    }

    .product-caption {
        min-height: 90px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
    }

    .all-price {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }

    .product-name {
        width: 100%;
        font-family: $font_m;
        font-weight: 400;
        font-size: 13px;
        text-align: left;
        margin: 15px auto 0;

        &:hover {
            color: $gold;
        }
    }

    .product-price {
        width: 100%;
        margin: 0;
        font-family: $font_m;
        font-weight: 600;
        font-size: 19px !important;
        color: $black;
        text-align: left;

        span {
            white-space: nowrap;
            font-family: $font_m;
            font-weight: 600;
            font-size: 19px !important;
            color: $black;
            text-decoration: none;
        }

        &__discount {
            display: inline;
            position: relative;
            width: auto;
            font-weight: 600;
            font-size: 15px !important;
            color: $black;
            text-align: right;
            opacity: .2;

            span {
                font-weight: 600;
                font-size: 15px !important;
                color: $black;
            }

            &::before {
                content: '';
                position: absolute;
                width: 100%;
                top: 58%;
                left: 0;
                transform: translateY(-50%);
                border-bottom: 1px solid $black;
            }
        }
    }

    &:hover {
        -webkit-box-shadow: none;
        box-shadow: none;

        .action_item_more {
            opacity: 1;
            z-index: 5;

            &__text {
                a {
                    opacity: 1;
                }

                &::before,
                &::after {
                    right: -20px;
                    left: -20px;
                }
            }
        }

        .img-responsive {
            transform: none;
        }
    }
}

.category_catalog {
    position: relative;
    z-index: 5;

    .sub-menuCatalog {
        text-decoration: none;
        position: static;

        span {
            text-transform: uppercase;
            font-family: $font_m;
            font-weight: 600;
            font-style: normal;
            font-size: 13px;
        }
    }

    .block {
        &.color-scheme-3 {
            padding: 0;
        }
    }

    &__one-product {
        h1 {
            width: 100%;
            margin: 30px auto;
            font-weight: 600;
            font-size: 23px;
        }

        .product-list {
            padding: 0;
            margin-bottom: 40px;
            border-right: none;
            border-left: none;
            border-bottom: none;
            background-color: $white-gray;

            @include max-screen(576px) {
                overflow: inherit;
            }

            & > .row {
                display: flex;
                flex-wrap: wrap;

                h1 {
                    @include max-screen(1200px) {
                        text-align: center;
                    }
                }
            }
        }

        .img-col {
            position: relative;
            width: calc(50% + 90px);
            border: none;
            padding: 0;

            @include max-screen(1200px) {
                width: calc(50%);
            }

            @include max-screen(1024px) {
                width: 100%;
            }

            .product-image {
                display: block;
                margin: 0;
                height: 100%;

                @include max-screen(1024px) {
                    display: flex;
                    justify-content: center;
                }
            }
        }

        .product-image-first-col {
            @include max-screen(1200px) {
                display: none;
            }
        }

        .product-image-second-col {
            display: block;
            height: 100%;
        }

        .with-socials {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            width: calc(50% - 90px);
            height: auto;
            padding: 0;

            @include max-screen(1200px) {
                width: 50%;
            }

            @include max-screen(1024px) {
                width: 100%;
                margin-top: 50px;
            }

            .field {
                display: flex;
                justify-content: space-between;
            }

            label {
                padding: 0;
                margin: 0;
            }
        }

        #select-box {
            display: inline-flex;
            margin: 0;
            height: auto;

            .box-element {
                margin-right: 20px;
                border: 1px solid $blue;
                font-family: $font_m;
                font-weight: 400;
                font-size: 17px;

                -webkit-transition: all .5s ease;
                -moz-transition: all .5s ease;
                -ms-transition: all .5s ease;
                -o-transition: all .5s ease;
                transition: all .5s ease;

                &:last-child {
                    margin-right: 0;
                    border: 1px solid $blue;
                }

                &.active {
                    pointer-events: none;
                    background-color: $blue;
                }

                &:hover {
                    background-color: $gold;
                    border: 1px solid $gold;
                }
            }
        }

        .all-price {
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                width: 100%;
                display: block;
                margin: 0;
                text-transform: uppercase;
                font-family: $font_m;
                font-weight: 600;
                font-style: normal;
                font-size: 13px !important;
                text-align: left;
            }
        }

        .field-price,
        .field-price-old {
            padding: 0 !important;
            margin: 0;

            p {
                width: 100%;
                display: inline;
                white-space: nowrap;
            }

            &::before,
            &::after {
                display: none;
            }
        }

        .field-price {
            text-align: right;

            p {
                font-family: $font_m;
                font-weight: 600;
                font-size: 19px !important;
                color: $gold;
            }
        }

        .field-price-old {
            text-align: right;

            p {
                padding-right: 20px;
                font-weight: 500;
                font-size: 13px !important;
                color: $border-color !important;
            }
        }

        .list-shopping-cart {
            & > .row {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                padding: 25px 0 0 !important;
                margin-top: 25px !important;
                width: 100%;
                border-top: 1px solid $border-color;

                @include max-screen(1024px) {
                    justify-content: space-around;
                }

                @include max-screen(480px) {
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                }

                &::before,
                &::after {
                    display: none;
                }

                & > div {
                    a:first-child {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 15px 10px;
                        width: 100%;
                        color: $blue;
                        background-color: transparent;
                        border: 2px solid $blue;
                        font-size: 13px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 1.5px;

                        -webkit-transition: all .5s ease;
                        -moz-transition: all .5s ease;
                        -ms-transition: all .5s ease;
                        -o-transition: all .5s ease;
                        transition: all .5s ease;

                        @include max-screen(480px) {
                            height: 60px;
                        }

                        &::before {
                            position: relative;
                            font-family: "Flaticon";
                            padding-right: 15px;
                            color: $blue;

                            -webkit-transition: color .5s ease;
                            -moz-transition: color .5s ease;
                            -ms-transition: color .5s ease;
                            -o-transition: color .5s ease;
                            transition: color .5s ease;

                            @include max-screen(576px) {
                                display: none;
                            }
                        }

                        &:hover {
                            color: $white;
                            border-color: $gold;
                            background-color: $gold;

                            &::before {
                                color: $white;
                            }
                        }
                    }

                    &:first-child {
                        width: 220px;
                        display: flex;
                        flex-direction: column-reverse;

                        @include max-screen(1024px) {
                            width: 285px;
                        }

                        @include max-screen(768px) {
                            width: 50%;
                            margin-right: 20px;
                        }

                        @include max-screen(480px) {
                            display: flex;
                            align-items: center;
                            margin-right: 0;
                        }

                        a {
                            &:first-child {
                                &::before {
                                    font-size: 20px;
                                    content: '\f101';
                                }
                            }
                        }
                    }

                    &:last-child {
                        width: calc(100% - 245px);
                        padding: 0;
                        margin: 0;

                        @include max-screen(1024px) {
                            max-width: 285px;
                        }

                        @include max-screen(768px) {
                            width: 50%;
                            max-width: 100%;
                            margin-left: 20px;
                        }

                        @include max-screen(480px) {
                            margin-top: 30px;
                            margin-left: 0;
                        }

                        a {
                            margin: 0;

                            &:first-child {
                                &::before {
                                    font-size: 25px;
                                    content: '\f107';
                                }
                            }
                        }
                    }
                }
            }

            .btn-read-all {
                margin: 0;
            }

            .btn-clear {
                margin: 0 0 25px;
                font-family: $font_m;
                font-weight: 600;
                font-style: normal;
                font-size: 13px !important;
                color: $blue;
                text-decoration: underline;

                -webkit-transition: color .5s ease;
                -moz-transition: color .5s ease;
                -ms-transition: color .5s ease;
                -o-transition: color .5s ease;
                transition: color .5s ease;

                &:hover {
                    color: $light-blue !important;
                    text-decoration: underline !important;
                }
            }
        }

        .gal_nav {
            img {
                width: 80px;
                height: 120px;
                object-fit: cover;
                object-position: 50% 0;
            }

            div {
                margin: 0;
            }

            .slick-slide {
                margin-bottom: 40px;
            }
        }

        .gal-main {
            display: block;
            margin: 0;
            height: 100%;

            .slick-list,
            .slick-track {
                display: block;
                height: 100%;
            }

            .slick-arrow {
                top: 50%;
                transform: translateY(-50%);
            }

            .slick-prev {
                left: -50px;

                @include max-screen(1200px) {
                    left: 15px;
                }
            }

            .slick-next {
                left: calc(100% + 40px);

                @include max-screen(1200px) {
                    left: calc(100% - 30px);
                }
            }
        }

        .product-image {
            width: 100%;
            margin: 0;

            &-first-col {
                width: 80px;
                margin-top: 0;
                margin-right: 90px;
            }

            &-second-col {
                margin: 0;
                max-width: 400px;
                width: 100%;

                @include max-screen(768px) {
                    margin: 0 auto;
                }

                img {
                    display: block !important;
                    max-width: 100%;
                    height: 100% !important;
                    object-fit: cover;
                    object-position: 50% 0;

                    @include max-screen(768px) {
                        height: 400px !important;
                        //object-fit: contain;
                    }
                }
            }
        }

        .fields-special-row {
            padding: 25px 0 0;
            margin: 0 !important;

            .fields-special {
                display: flex;
                flex-direction: column;

                & > div {
                    width: 100%;
                    padding: 0;
                }

                .field {
                    padding-bottom: 25px;
                }
            }
        }
    }
}

.product-new.sale {
    background-color: $blue;
}

#product-zoom {
    border: none;
}

.sum-item {
    display: inline;
    position: relative;
    width: auto;
    font-weight: 600;
    font-size: 15px;
    text-align: center;

    p, span {
        color: $black;
    }

    p {
        opacity: .2;
        margin-bottom: 30px;
    }
}