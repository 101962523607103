@import "../fonts/Romul/stylesheet.css";
@import "../fonts/Montserrat/stylesheet.css";
@import "../fonts/Flaticon/font/flaticon.css";

[class*="flaticon-"]:before,
[class*="flaticon-"]:after {
    font-weight: normal;
    margin-left: 0;
}

$font_m: 'Montserrat', sans-serif;
$font_r: 'Romul', sans-serif;