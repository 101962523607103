#footer {

	background: linear-gradient( 180deg, rgba(0, 0, 0, 0.45) 5%, rgba(0, 0, 0, 0.75) 100%), linear-gradient( 180deg, rgba(0, 0, 0, 0.45) 50%, rgba(0, 0, 0, 0.55) 100%), url(/images/footer_bg.png);
	background-size: cover;
	
	
	& .header-footer {
		margin-bottom: 15px;
	}
	
	
	& .footer-information {
		
		padding: 25px 0 0;
		background: transparent;
		font-size: 13px;
		
		& .footer-categories.list-unstyled {
			line-height: 2.3;
		}
		
		
		& a {
			text-transform: uppercase;
		}
		
		& .container {
			
			& .header-footer {
				
				border-bottom: 1px solid rgba(255, 255, 255, 0.2);
				
				& .header3.h3 {
					font-size: 13px;
					text-transform: uppercase;
					text-align: left !important;
					margin-bottom: 15px;
				}
			}
			
			& .row {
				
				border-bottom: 1px solid rgba(255, 255, 255, 0.2);
				padding-bottom: 30px;
				
				& .col-xs-6.col-md-3:hover .header-footer { 
					
					border-bottom: 1px solid $white; 
					
				}
				
				& .col-xs-6.col-md-3 {
					
					& .footer-categories.list-unstyled {
						& li {
							& a {
									font-weight: normal;
									font-style: normal;
									font-family: Montserrat,sans-serif;
							}
						}
					}
					
					& p {
						font-weight: normal;
						font-style: normal;
						font-family: Montserrat,sans-serif;
						font-size: 12px;
						margin: 0 auto;
					}
					
					& p span {
						font-weight: normal;
						font-style: normal;
						font-family: Montserrat,sans-serif;
					}
					
					& .fast-contact {
						margin: 0 auto;
					}					
				}
			}
		}
	}
	
	& .color-scheme-dark {
		background-color: transparent;
	}
	
	& .header-footer span.header3 {
		color: #d0aa8c;
	}
	
	& .icon-angle-right:before {
		display: none;
	}
	
	p, a, span {
        font-family: 'Romul', sans-serif;
        color: $white;
    }
	
	a:hover {
        color: #d0aa8c;
    }
	
	& .footer-copy {
			
			& .logo-copy {
				display: inline-block;
				background: url(/templates/zimniysezon/img/logo_zim_sezon3.png);
				width: 100%;
				min-width: 10px;
				max-width: 259px;
				height: 100%;
				min-height: 54px;
				box-sizing: border-box;
				background-repeat: no-repeat;
			}
	}
	
	& .all_right_reserved {
		color: #d0aa8c;
		font-family: Montserrat;
		text-transform: uppercase;
		font-size: 13px;
	}
	
	
	& .social-link {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
			flex-wrap: wrap;
	}
	
	& .social-link__man, & .social-link__woman {
		display: -ms-flexbox;
		display: flex;
		
	}
	
	& .social-link .flaticon-instagram-logo {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: center;
		justify-content: center;
		-ms-flex-align: center;
		align-items: center;
		padding-right: 10px;
	}
	
	& .social-link .flaticon-instagram-logo:before {
		font-size: 19px;
		color: #d0aa8c;
	}
	
	& .social-link__man .wrapper, & .social-link__woman .wrapper {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-pack: center;
		justify-content: center;
		-ms-flex-align: start;
		align-items: flex-start;
	}
	
	& .social-link__man .wrapper p, & .social-link__woman .wrapper p {
		line-height: 13px;
	}

	& .social-link__title {
		letter-spacing: 1.8px;
		text-transform: uppercase;
		transition: color .5s ease;
		font-size: 10px !important;
		font-family: Romul !important;
		
	}
	
	& .social-link__and {
		font-family: Romul,sans-serif !important;
		font-size: 15px !important;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: center;
		justify-content: center;
		-ms-flex-align: center;
		align-items: center;
		color: #d0aa8c;
		padding: 0 5px;
	}
	
	& .social-link__text {
		font-family: Montserrat,sans-serif !important;
		font-weight: 500;
		font-size: 7px !important;
	}

	& .title_line {
		color: white;
		font-family: Montserrat;
		font-size: 13px;
		margin-top: 10px;
		margin-bottom: 10px;
	}
	
	& .contacts_on_cart {
		margin-top: 5px;
	}
	
	& .phone_line_title {
    margin-top: 20px !important;
}
}