.block-breadcrumb {
    /* border-bottom: 1px solid rgba($black, .2); */
    border-bottom: 1px solid $border-color;

    @include max-screen(992px) {
        display: none;
    }

    .container {
        min-height: 30px;
        display: flex;
        align-items: center;
    }
}

.block-pagination {
    a {
        &:hover,
        &:focus {
            border-color: $gold !important;
            background: $gold !important;
            color: $black !important;
        }
    }
}

.breadcrumb {
    display: flex;
    align-items: center;
    padding: 0;

    li {
        //display: inherit;
        white-space: nowrap;
        max-width: 150px;
        max-height: 13px;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 10px;
        font-family: $font_m;
        font-weight: 600;
        letter-spacing: 2px;
        text-transform: uppercase;

        &::after {
            content: '/';
            font-weight: 600;
        }

        a {
            font-size: 10px;
			font-family: $font_m;

            &:hover {
                color: $gold;
            }
        }

        &.active {
            font-size: 11px;
            color: $gold;
        }
    }
}