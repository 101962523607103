// Header Cart
.shopping-cart-content {
    border-top: 3px solid $gold !important;
    &::before {
        border-top: 6px solid $gold !important;
    }
}

.shopping-cart-items {
    img {
        margin-right: 10px;
    }

    .item {
        height: auto !important;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > div {
            position: relative !important;
            margin: 0 !important;

            p {
                padding: 5px 0 0 !important;

                &:last-child {
                    height: auto !important;
                }
            }
        }

        & > a {
            width: auto !important;
            height: auto !important;

            &:hover i::before {
                color: $gold;
            }

            i {
                padding: 0;
                margin: 0;
            }
        }
    }

    table {
        tbody {
            width: 100%;
        }

        tr {
            display: flex;
            justify-content: space-between;
        }

        td {
            font-family: "Montserrat", sans-serif;
            font-weight: 700;
            padding: 5px 0 !important;

            b {
                text-transform: uppercase;
                font-weight: 500;
            }
        }
    }

    .total {
        display: flex;
        flex-direction: column;

        a {
            height: auto !important;
            position: inherit !important;
            margin: 0 auto !important;

            &:hover {
                background-color: $gold;
                border-color: $gold;
            }
        }
    }
}