@import url(../fonts/Romul/stylesheet.css);
@import url(../fonts/Montserrat/stylesheet.css);
@import url(../fonts/Flaticon/font/flaticon.css);
[class*="flaticon-"]:before,
[class*="flaticon-"]:after {
  font-weight: normal;
  margin-left: 0; }

body {
  min-width: 300px; }

h1, h2, h3 h4, h5 {
  font-family: "Romul", sans-serif; }

p, a, span, ul, li {
  font-family: "Montserrat", sans-serif; }

.item.pull-left.is-empty {
  font-family: "Montserrat", sans-serif !important;
  font-size: 11px !important;
  padding: 15px 0 !important;
  margin: 0 !important; }

.header-bottom-line {
  display: none !important; }

#header .header-main,
#header .header-main-fixed {
  width: 100%;
  z-index: 999;
  background: #000000;
  /* Old browsers */
  background: -moz-linear-gradient(left, #000000 0%, #454857 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #000000 0%, #454857 100%);
  /* Chrome10-25, Safari5.1-6 */
  background: linear-gradient(to right, #000000 0%, #454857 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }
  #header .header-main .container,
  #header .header-main-fixed .container {
    padding: 0; }
    @media only screen and (max-width: 992px) {
      #header .header-main .container,
      #header .header-main-fixed .container {
        width: 100%;
        padding: 0 30px; } }
  @media only screen and (min-width: 1024px) {
    #header .header-main,
    #header .header-main-fixed {
      position: fixed; } }

@media only screen and (min-width: 1024px) {
  #header + section {
    padding-top: 80px; } }

#header .header-logo {
  display: block;
  width: 100%;
  min-width: 210px;
  height: 100%;
  min-height: 80px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  background-position: 50% 50%; }
  @media only screen and (max-width: 576px) {
    #header .header-logo {
      width: 50vw;
      min-width: inherit; } }

#header p, #header a, #header span {
  font-family: 'Romul', sans-serif;
  color: #ffffff; }

#header a {
  text-decoration: none; }

#header p {
  margin: 0; }

#header .navbar {
  display: flex; }
  @media only screen and (max-width: 992px) {
    #header .navbar {
      display: none; } }
  @media only screen and (max-width: 1200px) {
    #header .navbar + .header-separator {
      display: none; } }

#header nav {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  margin: 0; }
  #header nav ul {
    display: flex;
    padding: 0;
    margin: 0; }
  #header nav li,
  #header nav li.active {
    font-family: Romul, sans-serif;
    font-size: 13px;
    letter-spacing: .5px;
    list-style: none;
    text-transform: uppercase; }
    #header nav li.active,
    #header nav li.active.active {
      color: #d0aa8c; }
  #header nav li:hover a {
    color: #d0aa8c; }
  @media only screen and (max-width: 992px) {
    #header nav li:last-child .header-separator {
      display: none; } }

#header .social-link {
  display: flex; }
  @media only screen and (max-width: 1200px) {
    #header .social-link {
      display: none; } }
  #header .social-link .flaticon-instagram-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 15px; }
    #header .social-link .flaticon-instagram-logo::before {
      font-size: 23px;
      color: #d0aa8c; }
  #header .social-link__and {
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #d0aa8c;
    padding: 0 15px; }
  #header .social-link__title {
    letter-spacing: 1.8px;
    text-transform: uppercase;
    -webkit-transition: color .5s ease;
    -moz-transition: color .5s ease;
    -ms-transition: color .5s ease;
    -o-transition: color .5s ease;
    transition: color .5s ease; }
  #header .social-link__text {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 10px; }
  #header .social-link__man, #header .social-link__woman {
    display: flex; }
    #header .social-link__man .wrapper, #header .social-link__woman .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start; }
      #header .social-link__man .wrapper p, #header .social-link__woman .wrapper p {
        line-height: 13px; }
    #header .social-link__man:hover .social-link__title, #header .social-link__woman:hover .social-link__title {
      color: #d0aa8c; }

#header .block-phones {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media only screen and (max-width: 992px) {
    #header .block-phones {
      display: none; } }
  #header .block-phones a {
    font-size: 13px;
    padding: 2px 0; }
    #header .block-phones a:hover {
      color: #d0aa8c; }

#header .login-col {
  display: flex; }
  #header .login-col > div {
    margin: 0; }

#header .block-search,
#header .block-cart,
#header .header-mini-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  background: inherit !important; }

#header .block-cart a {
  position: relative;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0; }
  #header .block-cart a span:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 11px;
    top: 35%;
    right: -2px;
    transform: translateY(-50%);
    position: absolute;
    background-color: #ffffff;
    color: #454857; }

#header .flaticon-magnifying-glass,
#header .flaticon-cancel {
  opacity: 1; }
  #header .flaticon-magnifying-glass:before,
  #header .flaticon-cancel:before {
    color: #d0aa8c;
    font-size: 23px; }

#header .flaticon-shopping-bag {
  color: #d0aa8c !important;
  top: 0 !important;
  position: relative !important;
  width: auto !important;
  height: auto !important;
  line-height: inherit !important;
  border-radius: inherit !important;
  background: inherit !important; }
  #header .flaticon-shopping-bag:before {
    font-size: 28px; }

#header .header-mini-cart {
  height: auto;
  margin: 0; }

#header .header-separator {
  position: relative;
  min-height: 80px;
  height: 100%;
  padding: 0 17px; }
  #header .header-separator:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1px;
    height: 34px;
    background-color: rgba(255, 255, 255, 0.3); }

#header .navbar-mobile {
  display: none !important;
  margin-bottom: 20px; }
  @media only screen and (max-width: 992px) {
    #header .navbar-mobile {
      display: flex !important; } }
  #header .navbar-mobile ul {
    width: 100%;
    display: flex;
    justify-content: center; }
  #header .navbar-mobile li {
    padding: 0 30px; }
    #header .navbar-mobile li .header-separator {
      display: none; }

#header-search {
  cursor: pointer;
  margin: 0; }

#container-fixed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0 !important;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -ms-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease; }
  @media only screen and (max-width: 1200px) {
    #container-fixed {
      justify-content: center; } }
  #container-fixed > div {
    padding: 0; }
    @media only screen and (max-width: 992px) {
      #container-fixed > div:first-child + .header-separator {
        display: none; } }

.block-breadcrumb {
  /* border-bottom: 1px solid rgba($black, .2); */
  border-bottom: 1px solid #dfdfdf; }
  @media only screen and (max-width: 992px) {
    .block-breadcrumb {
      display: none; } }
  .block-breadcrumb .container {
    min-height: 30px;
    display: flex;
    align-items: center; }

.block-pagination a:hover, .block-pagination a:focus {
  border-color: #d0aa8c !important;
  background: #d0aa8c !important;
  color: #000000 !important; }

.breadcrumb {
  display: flex;
  align-items: center;
  padding: 0; }
  .breadcrumb li {
    white-space: nowrap;
    max-width: 150px;
    max-height: 13px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 10px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase; }
    .breadcrumb li::after {
      content: '/';
      font-weight: 600; }
    .breadcrumb li a {
      font-size: 10px;
      font-family: "Montserrat", sans-serif; }
      .breadcrumb li a:hover {
        color: #d0aa8c; }
    .breadcrumb li.active {
      font-size: 11px;
      color: #d0aa8c; }

#footer {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.45) 5%, rgba(0, 0, 0, 0.75) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.45) 50%, rgba(0, 0, 0, 0.55) 100%), url(/images/footer_bg.png);
  background-size: cover; }
  #footer .header-footer {
    margin-bottom: 15px; }
  #footer .footer-information {
    padding: 25px 0 0;
    background: transparent;
    font-size: 13px; }
    #footer .footer-information .footer-categories.list-unstyled {
      line-height: 2.3; }
    #footer .footer-information a {
      text-transform: uppercase; }
    #footer .footer-information .container .header-footer {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
      #footer .footer-information .container .header-footer .header3.h3 {
        font-size: 13px;
        text-transform: uppercase;
        text-align: left !important;
        margin-bottom: 15px; }
    #footer .footer-information .container .row {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      padding-bottom: 30px; }
      #footer .footer-information .container .row .col-xs-6.col-md-3:hover .header-footer {
        border-bottom: 1px solid #ffffff; }
      #footer .footer-information .container .row .col-xs-6.col-md-3 .footer-categories.list-unstyled li a {
        font-weight: normal;
        font-style: normal;
        font-family: Montserrat,sans-serif; }
      #footer .footer-information .container .row .col-xs-6.col-md-3 p {
        font-weight: normal;
        font-style: normal;
        font-family: Montserrat,sans-serif;
        font-size: 12px;
        margin: 0 auto; }
      #footer .footer-information .container .row .col-xs-6.col-md-3 p span {
        font-weight: normal;
        font-style: normal;
        font-family: Montserrat,sans-serif; }
      #footer .footer-information .container .row .col-xs-6.col-md-3 .fast-contact {
        margin: 0 auto; }
  #footer .color-scheme-dark {
    background-color: transparent; }
  #footer .header-footer span.header3 {
    color: #d0aa8c; }
  #footer .icon-angle-right:before {
    display: none; }
  #footer p, #footer a, #footer span {
    font-family: 'Romul', sans-serif;
    color: #ffffff; }
  #footer a:hover {
    color: #d0aa8c; }
  #footer .footer-copy .logo-copy {
    display: inline-block;
    background: url(/templates/zimniysezon/img/logo_zim_sezon3.png);
    width: 100%;
    min-width: 10px;
    max-width: 259px;
    height: 100%;
    min-height: 54px;
    box-sizing: border-box;
    background-repeat: no-repeat; }
  #footer .all_right_reserved {
    color: #d0aa8c;
    font-family: Montserrat;
    text-transform: uppercase;
    font-size: 13px; }
  #footer .social-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  #footer .social-link__man, #footer .social-link__woman {
    display: -ms-flexbox;
    display: flex; }
  #footer .social-link .flaticon-instagram-logo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    padding-right: 10px; }
  #footer .social-link .flaticon-instagram-logo:before {
    font-size: 19px;
    color: #d0aa8c; }
  #footer .social-link__man .wrapper, #footer .social-link__woman .wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: start;
    align-items: flex-start; }
  #footer .social-link__man .wrapper p, #footer .social-link__woman .wrapper p {
    line-height: 13px; }
  #footer .social-link__title {
    letter-spacing: 1.8px;
    text-transform: uppercase;
    transition: color .5s ease;
    font-size: 10px !important;
    font-family: Romul !important; }
  #footer .social-link__and {
    font-family: Romul,sans-serif !important;
    font-size: 15px !important;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    color: #d0aa8c;
    padding: 0 5px; }
  #footer .social-link__text {
    font-family: Montserrat,sans-serif !important;
    font-weight: 500;
    font-size: 7px !important; }
  #footer .title_line {
    color: white;
    font-family: Montserrat;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 10px; }
  #footer .contacts_on_cart {
    margin-top: 5px; }
  #footer .phone_line_title {
    margin-top: 20px !important; }

.block-search .header-top-search {
  top: 80px !important;
  right: -17px !important;
  background-color: #f8f8f8 !important; }
  @media only screen and (max-width: 992px) {
    .block-search .header-top-search {
      top: 120px !important;
      right: -35px !important; } }

.block-search .top-search-form form {
  width: 100%;
  display: flex; }

.block-search .top-search-form input,
.block-search .top-search-form button {
  border: none;
  border-color: #f8f8f8;
  background-color: #f8f8f8; }

.block-search .top-search-form input {
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  box-sizing: border-box; }
  .block-search .top-search-form input:focus {
    outline: none; }

.block-search .top-search-form button {
  width: 60px !important;
  box-sizing: border-box;
  padding: 0 17px;
  border: none;
  border-left: 1px solid rgba(0, 0, 0, 0.2); }
  .block-search .top-search-form button:hover {
    background-color: #d0aa8c;
    border: none;
    border-left: 1px solid rgba(0, 0, 0, 0.2); }
  .block-search .top-search-form button i::before {
    font-size: 21px !important;
    color: #000000 !important;
    font-weight: 400 !important; }

#area {
  border: none !important; }

.shopping-cart-content {
  border-top: 3px solid #d0aa8c !important; }
  .shopping-cart-content::before {
    border-top: 6px solid #d0aa8c !important; }

.shopping-cart-items img {
  margin-right: 10px; }

.shopping-cart-items .item {
  height: auto !important;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .shopping-cart-items .item > div {
    position: relative !important;
    margin: 0 !important; }
    .shopping-cart-items .item > div p {
      padding: 5px 0 0 !important; }
      .shopping-cart-items .item > div p:last-child {
        height: auto !important; }
  .shopping-cart-items .item > a {
    width: auto !important;
    height: auto !important; }
    .shopping-cart-items .item > a:hover i::before {
      color: #d0aa8c; }
    .shopping-cart-items .item > a i {
      padding: 0;
      margin: 0; }

.shopping-cart-items table tbody {
  width: 100%; }

.shopping-cart-items table tr {
  display: flex;
  justify-content: space-between; }

.shopping-cart-items table td {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  padding: 5px 0 !important; }
  .shopping-cart-items table td b {
    text-transform: uppercase;
    font-weight: 500; }

.shopping-cart-items .total {
  display: flex;
  flex-direction: column; }
  .shopping-cart-items .total a {
    height: auto !important;
    position: inherit !important;
    margin: 0 auto !important; }
    .shopping-cart-items .total a:hover {
      background-color: #d0aa8c;
      border-color: #d0aa8c; }

.product-section {
  width: 100%;
  padding: 30px 0;
  box-sizing: border-box; }

.row.category_list_product,
.related-products-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 auto; }

.category_list_product article,
.related-products-list article {
  width: 25%;
  min-height: initial;
  height: auto;
  padding: 0;
  padding-right: 20px;
  margin-bottom: 30px; }
  @media only screen and (max-width: 1200px) {
    .category_list_product article,
    .related-products-list article {
      width: calc(100% / 3); } }
  @media only screen and (max-width: 992px) {
    .category_list_product article,
    .related-products-list article {
      width: 50%; } }
  @media only screen and (max-width: 576px) {
    .category_list_product article,
    .related-products-list article {
      width: 70%;
      padding: 0 !important;
      margin: 0 auto 50px; } }
  @media only screen and (max-width: 480px) {
    .category_list_product article,
    .related-products-list article {
      width: 80%; } }
  .category_list_product article:nth-child(4n),
  .related-products-list article:nth-child(4n) {
    padding-right: 0; }
    @media only screen and (max-width: 1200px) {
      .category_list_product article:nth-child(4n),
      .related-products-list article:nth-child(4n) {
        padding-right: 20px; } }
  @media only screen and (max-width: 1200px) {
    .category_list_product article:nth-child(3n),
    .related-products-list article:nth-child(3n) {
      padding-right: 0; } }
  @media only screen and (max-width: 992px) {
    .category_list_product article:nth-child(3n),
    .related-products-list article:nth-child(3n) {
      padding-right: 20px; } }
  @media only screen and (max-width: 992px) {
    .category_list_product article:nth-child(2n),
    .related-products-list article:nth-child(2n) {
      padding-right: 0; } }

.category_list_product .product,
.related-products-list .product {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  max-width: 100%;
  min-height: unset;
  height: unset;
  border: none;
  background-color: transparent; }
  .category_list_product .product .action_item_more,
  .related-products-list .product .action_item_more {
    opacity: 0;
    color: #ffffff;
    position: absolute;
    height: 400px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: -webkit-linear-gradient(135deg, rgba(69, 72, 87, 0.93), rgba(208, 170, 140, 0.93));
    background: -o-linear-gradient(135deg, rgba(69, 72, 87, 0.93), rgba(208, 170, 140, 0.93));
    background: linear-gradient(-45deg, rgba(69, 72, 87, 0.93), rgba(208, 170, 140, 0.93));
    -webkit-transition: all .5s ease-out;
    -o-transition: all .5s ease-out;
    transition: all .5s ease-out; }
    @media only screen and (max-width: 1024px) {
      .category_list_product .product .action_item_more,
      .related-products-list .product .action_item_more {
        display: none; } }
    .category_list_product .product .action_item_more__inner,
    .related-products-list .product .action_item_more__inner {
      position: absolute;
      top: 50%;
      left: 50%; }
    .category_list_product .product .action_item_more__text,
    .related-products-list .product .action_item_more__text {
      position: inherit;
      display: inline-block;
      text-transform: uppercase;
      font-family: "Romul", sans-serif;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
      .category_list_product .product .action_item_more__text a,
      .related-products-list .product .action_item_more__text a {
        font-size: 17px;
        display: block;
        color: #ffffff;
        text-decoration: none;
        margin: 20px 0;
        opacity: 0;
        -webkit-transition: all .5s ease;
        -o-transition: all .5s ease;
        transition: all .5s ease; }
      .category_list_product .product .action_item_more__text::before, .category_list_product .product .action_item_more__text::after,
      .related-products-list .product .action_item_more__text::before,
      .related-products-list .product .action_item_more__text::after {
        content: '';
        display: block;
        position: absolute;
        height: 1px;
        right: 50%;
        left: 50%;
        background: #f8f8f8;
        -webkit-transition: all .5s ease;
        -o-transition: all .5s ease;
        transition: all .5s ease; }
  .category_list_product .product .figure-hover-overlay,
  .related-products-list .product .figure-hover-overlay {
    max-width: 100%;
    height: 400px;
    margin: 0; }
    .category_list_product .product .figure-hover-overlay .img-responsive,
    .related-products-list .product .figure-hover-overlay .img-responsive {
      height: inherit;
      object-fit: cover;
      object-position: 50% 0;
      margin: auto; }
      .category_list_product .product .figure-hover-overlay .img-responsive + .bar,
      .related-products-list .product .figure-hover-overlay .img-responsive + .bar {
        display: none; }
  .category_list_product .product .product-caption,
  .related-products-list .product .product-caption {
    min-height: 90px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0; }
  .category_list_product .product .all-price,
  .related-products-list .product .all-price {
    display: flex;
    flex-direction: row-reverse;
    align-items: center; }
  .category_list_product .product .product-name,
  .related-products-list .product .product-name {
    width: 100%;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 13px;
    text-align: left;
    margin: 15px auto 0; }
    .category_list_product .product .product-name:hover,
    .related-products-list .product .product-name:hover {
      color: #d0aa8c; }
  .category_list_product .product .product-price,
  .related-products-list .product .product-price {
    width: 100%;
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 19px !important;
    color: #000000;
    text-align: left; }
    .category_list_product .product .product-price span,
    .related-products-list .product .product-price span {
      white-space: nowrap;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 19px !important;
      color: #000000;
      text-decoration: none; }
    .category_list_product .product .product-price__discount,
    .related-products-list .product .product-price__discount {
      display: inline;
      position: relative;
      width: auto;
      font-weight: 600;
      font-size: 15px !important;
      color: #000000;
      text-align: right;
      opacity: .2; }
      .category_list_product .product .product-price__discount span,
      .related-products-list .product .product-price__discount span {
        font-weight: 600;
        font-size: 15px !important;
        color: #000000; }
      .category_list_product .product .product-price__discount::before,
      .related-products-list .product .product-price__discount::before {
        content: '';
        position: absolute;
        width: 100%;
        top: 58%;
        left: 0;
        transform: translateY(-50%);
        border-bottom: 1px solid #000000; }
  .category_list_product .product:hover,
  .related-products-list .product:hover {
    -webkit-box-shadow: none;
    box-shadow: none; }
    .category_list_product .product:hover .action_item_more,
    .related-products-list .product:hover .action_item_more {
      opacity: 1;
      z-index: 5; }
      .category_list_product .product:hover .action_item_more__text a,
      .related-products-list .product:hover .action_item_more__text a {
        opacity: 1; }
      .category_list_product .product:hover .action_item_more__text::before, .category_list_product .product:hover .action_item_more__text::after,
      .related-products-list .product:hover .action_item_more__text::before,
      .related-products-list .product:hover .action_item_more__text::after {
        right: -20px;
        left: -20px; }
    .category_list_product .product:hover .img-responsive,
    .related-products-list .product:hover .img-responsive {
      transform: none; }

.category_catalog {
  position: relative;
  z-index: 5; }
  .category_catalog .sub-menuCatalog {
    text-decoration: none;
    position: static; }
    .category_catalog .sub-menuCatalog span {
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 13px; }
  .category_catalog .block.color-scheme-3 {
    padding: 0; }
  .category_catalog__one-product h1 {
    width: 100%;
    margin: 30px auto;
    font-weight: 600;
    font-size: 23px; }
  .category_catalog__one-product .product-list {
    padding: 0;
    margin-bottom: 40px;
    border-right: none;
    border-left: none;
    border-bottom: none;
    background-color: #f8f8f8; }
    @media only screen and (max-width: 576px) {
      .category_catalog__one-product .product-list {
        overflow: inherit; } }
    .category_catalog__one-product .product-list > .row {
      display: flex;
      flex-wrap: wrap; }
      @media only screen and (max-width: 1200px) {
        .category_catalog__one-product .product-list > .row h1 {
          text-align: center; } }
  .category_catalog__one-product .img-col {
    position: relative;
    width: calc(50% + 90px);
    border: none;
    padding: 0; }
    @media only screen and (max-width: 1200px) {
      .category_catalog__one-product .img-col {
        width: calc(50%); } }
    @media only screen and (max-width: 1024px) {
      .category_catalog__one-product .img-col {
        width: 100%; } }
    .category_catalog__one-product .img-col .product-image {
      display: block;
      margin: 0;
      height: 100%; }
      @media only screen and (max-width: 1024px) {
        .category_catalog__one-product .img-col .product-image {
          display: flex;
          justify-content: center; } }
  @media only screen and (max-width: 1200px) {
    .category_catalog__one-product .product-image-first-col {
      display: none; } }
  .category_catalog__one-product .product-image-second-col {
    display: block;
    height: 100%; }
  .category_catalog__one-product .with-socials {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: calc(50% - 90px);
    height: auto;
    padding: 0; }
    @media only screen and (max-width: 1200px) {
      .category_catalog__one-product .with-socials {
        width: 50%; } }
    @media only screen and (max-width: 1024px) {
      .category_catalog__one-product .with-socials {
        width: 100%;
        margin-top: 50px; } }
    .category_catalog__one-product .with-socials .field {
      display: flex;
      justify-content: space-between; }
    .category_catalog__one-product .with-socials label {
      padding: 0;
      margin: 0; }
  .category_catalog__one-product #select-box {
    display: inline-flex;
    margin: 0;
    height: auto; }
    .category_catalog__one-product #select-box .box-element {
      margin-right: 20px;
      border: 1px solid #454857;
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      font-size: 17px;
      -webkit-transition: all .5s ease;
      -moz-transition: all .5s ease;
      -ms-transition: all .5s ease;
      -o-transition: all .5s ease;
      transition: all .5s ease; }
      .category_catalog__one-product #select-box .box-element:last-child {
        margin-right: 0;
        border: 1px solid #454857; }
      .category_catalog__one-product #select-box .box-element.active {
        pointer-events: none;
        background-color: #454857; }
      .category_catalog__one-product #select-box .box-element:hover {
        background-color: #d0aa8c;
        border: 1px solid #d0aa8c; }
  .category_catalog__one-product .all-price {
    display: flex;
    justify-content: center;
    align-items: center; }
    .category_catalog__one-product .all-price p {
      width: 100%;
      display: block;
      margin: 0;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 13px !important;
      text-align: left; }
  .category_catalog__one-product .field-price,
  .category_catalog__one-product .field-price-old {
    padding: 0 !important;
    margin: 0; }
    .category_catalog__one-product .field-price p,
    .category_catalog__one-product .field-price-old p {
      width: 100%;
      display: inline;
      white-space: nowrap; }
    .category_catalog__one-product .field-price::before, .category_catalog__one-product .field-price::after,
    .category_catalog__one-product .field-price-old::before,
    .category_catalog__one-product .field-price-old::after {
      display: none; }
  .category_catalog__one-product .field-price {
    text-align: right; }
    .category_catalog__one-product .field-price p {
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 19px !important;
      color: #d0aa8c; }
  .category_catalog__one-product .field-price-old {
    text-align: right; }
    .category_catalog__one-product .field-price-old p {
      padding-right: 20px;
      font-weight: 500;
      font-size: 13px !important;
      color: #dfdfdf !important; }
  .category_catalog__one-product .list-shopping-cart > .row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 25px 0 0 !important;
    margin-top: 25px !important;
    width: 100%;
    border-top: 1px solid #dfdfdf; }
    @media only screen and (max-width: 1024px) {
      .category_catalog__one-product .list-shopping-cart > .row {
        justify-content: space-around; } }
    @media only screen and (max-width: 480px) {
      .category_catalog__one-product .list-shopping-cart > .row {
        flex-direction: column;
        justify-content: space-between;
        align-items: center; } }
    .category_catalog__one-product .list-shopping-cart > .row::before, .category_catalog__one-product .list-shopping-cart > .row::after {
      display: none; }
    .category_catalog__one-product .list-shopping-cart > .row > div a:first-child {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 10px;
      width: 100%;
      color: #454857;
      background-color: transparent;
      border: 2px solid #454857;
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 1.5px;
      -webkit-transition: all .5s ease;
      -moz-transition: all .5s ease;
      -ms-transition: all .5s ease;
      -o-transition: all .5s ease;
      transition: all .5s ease; }
      @media only screen and (max-width: 480px) {
        .category_catalog__one-product .list-shopping-cart > .row > div a:first-child {
          height: 60px; } }
      .category_catalog__one-product .list-shopping-cart > .row > div a:first-child::before {
        position: relative;
        font-family: "Flaticon";
        padding-right: 15px;
        color: #454857;
        -webkit-transition: color .5s ease;
        -moz-transition: color .5s ease;
        -ms-transition: color .5s ease;
        -o-transition: color .5s ease;
        transition: color .5s ease; }
        @media only screen and (max-width: 576px) {
          .category_catalog__one-product .list-shopping-cart > .row > div a:first-child::before {
            display: none; } }
      .category_catalog__one-product .list-shopping-cart > .row > div a:first-child:hover {
        color: #ffffff;
        border-color: #d0aa8c;
        background-color: #d0aa8c; }
        .category_catalog__one-product .list-shopping-cart > .row > div a:first-child:hover::before {
          color: #ffffff; }
    .category_catalog__one-product .list-shopping-cart > .row > div:first-child {
      width: 220px;
      display: flex;
      flex-direction: column-reverse; }
      @media only screen and (max-width: 1024px) {
        .category_catalog__one-product .list-shopping-cart > .row > div:first-child {
          width: 285px; } }
      @media only screen and (max-width: 768px) {
        .category_catalog__one-product .list-shopping-cart > .row > div:first-child {
          width: 50%;
          margin-right: 20px; } }
      @media only screen and (max-width: 480px) {
        .category_catalog__one-product .list-shopping-cart > .row > div:first-child {
          display: flex;
          align-items: center;
          margin-right: 0; } }
      .category_catalog__one-product .list-shopping-cart > .row > div:first-child a:first-child::before {
        font-size: 20px;
        content: '\f101'; }
    .category_catalog__one-product .list-shopping-cart > .row > div:last-child {
      width: calc(100% - 245px);
      padding: 0;
      margin: 0; }
      @media only screen and (max-width: 1024px) {
        .category_catalog__one-product .list-shopping-cart > .row > div:last-child {
          max-width: 285px; } }
      @media only screen and (max-width: 768px) {
        .category_catalog__one-product .list-shopping-cart > .row > div:last-child {
          width: 50%;
          max-width: 100%;
          margin-left: 20px; } }
      @media only screen and (max-width: 480px) {
        .category_catalog__one-product .list-shopping-cart > .row > div:last-child {
          margin-top: 30px;
          margin-left: 0; } }
      .category_catalog__one-product .list-shopping-cart > .row > div:last-child a {
        margin: 0; }
        .category_catalog__one-product .list-shopping-cart > .row > div:last-child a:first-child::before {
          font-size: 25px;
          content: '\f107'; }
  .category_catalog__one-product .list-shopping-cart .btn-read-all {
    margin: 0; }
  .category_catalog__one-product .list-shopping-cart .btn-clear {
    margin: 0 0 25px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 13px !important;
    color: #454857;
    text-decoration: underline;
    -webkit-transition: color .5s ease;
    -moz-transition: color .5s ease;
    -ms-transition: color .5s ease;
    -o-transition: color .5s ease;
    transition: color .5s ease; }
    .category_catalog__one-product .list-shopping-cart .btn-clear:hover {
      color: #9f98ad !important;
      text-decoration: underline !important; }
  .category_catalog__one-product .gal_nav img {
    width: 80px;
    height: 120px;
    object-fit: cover;
    object-position: 50% 0; }
  .category_catalog__one-product .gal_nav div {
    margin: 0; }
  .category_catalog__one-product .gal_nav .slick-slide {
    margin-bottom: 40px; }
  .category_catalog__one-product .gal-main {
    display: block;
    margin: 0;
    height: 100%; }
    .category_catalog__one-product .gal-main .slick-list,
    .category_catalog__one-product .gal-main .slick-track {
      display: block;
      height: 100%; }
    .category_catalog__one-product .gal-main .slick-arrow {
      top: 50%;
      transform: translateY(-50%); }
    .category_catalog__one-product .gal-main .slick-prev {
      left: -50px; }
      @media only screen and (max-width: 1200px) {
        .category_catalog__one-product .gal-main .slick-prev {
          left: 15px; } }
    .category_catalog__one-product .gal-main .slick-next {
      left: calc(100% + 40px); }
      @media only screen and (max-width: 1200px) {
        .category_catalog__one-product .gal-main .slick-next {
          left: calc(100% - 30px); } }
  .category_catalog__one-product .product-image {
    width: 100%;
    margin: 0; }
    .category_catalog__one-product .product-image-first-col {
      width: 80px;
      margin-top: 0;
      margin-right: 90px; }
    .category_catalog__one-product .product-image-second-col {
      margin: 0;
      max-width: 400px;
      width: 100%; }
      @media only screen and (max-width: 768px) {
        .category_catalog__one-product .product-image-second-col {
          margin: 0 auto; } }
      .category_catalog__one-product .product-image-second-col img {
        display: block !important;
        max-width: 100%;
        height: 100% !important;
        object-fit: cover;
        object-position: 50% 0; }
        @media only screen and (max-width: 768px) {
          .category_catalog__one-product .product-image-second-col img {
            height: 400px !important; } }
  .category_catalog__one-product .fields-special-row {
    padding: 25px 0 0;
    margin: 0 !important; }
    .category_catalog__one-product .fields-special-row .fields-special {
      display: flex;
      flex-direction: column; }
      .category_catalog__one-product .fields-special-row .fields-special > div {
        width: 100%;
        padding: 0; }
      .category_catalog__one-product .fields-special-row .fields-special .field {
        padding-bottom: 25px; }

.product-new.sale {
  background-color: #454857; }

#product-zoom {
  border: none; }

.sum-item {
  display: inline;
  position: relative;
  width: auto;
  font-weight: 600;
  font-size: 15px;
  text-align: center; }
  .sum-item p, .sum-item span {
    color: #000000; }
  .sum-item p {
    opacity: .2;
    margin-bottom: 30px; }

.category_catalog .menu_and_filters_block {
  position: relative; }
  .category_catalog .menu_and_filters_block::before {
    position: absolute;
    width: 100%;
    top: 100%;
    content: '';
    border-bottom: 1px solid #dfdfdf; }
  .category_catalog .menu_and_filters_block .filter_block_line {
    padding: 0; }
  .category_catalog .menu_and_filters_block + .container {
    padding: 0;
    margin: 0 auto;
    box-sizing: border-box; }
    .category_catalog .menu_and_filters_block + .container .row {
      margin: 0 auto; }

.category_catalog .filters_block {
  display: none !important; }

.category_catalog .filter_block {
  position: relative; }
  .category_catalog .filter_block.items .global_menu_block {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    padding: 0;
    margin: 0 auto;
    min-height: 45px;
    list-style: none;
    align-items: center;
    background-color: #f8f8f8; }
    @media only screen and (max-width: 1200px) {
      .category_catalog .filter_block.items .global_menu_block {
        align-items: flex-start; } }
    .category_catalog .filter_block.items .global_menu_block .btn-default-1 {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%; }
    .category_catalog .filter_block.items .global_menu_block li {
      position: relative;
      z-index: 99;
      padding: 15px 20px 15px 25px;
      margin: 0;
      width: 100%;
      height: 50px;
      border-right: 1px solid #dfdfdf;
      border-bottom: 1px solid transparent;
      cursor: pointer;
      color: #454857;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -ms-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease; }
      @media only screen and (max-width: 1200px) {
        .category_catalog .filter_block.items .global_menu_block li {
          border-right: none; } }
      @media only screen and (max-width: 768px) {
        .category_catalog .filter_block.items .global_menu_block li {
          padding: 15px 20px 15px 15px; } }
      .category_catalog .filter_block.items .global_menu_block li:last-child {
        min-width: 25%; }
      .category_catalog .filter_block.items .global_menu_block li a {
        color: #454857; }
      .category_catalog .filter_block.items .global_menu_block li:hover a span {
        color: #9f98ad; }
    .category_catalog .filter_block.items .global_menu_block .li-catalog {
      position: relative;
      top: 0;
      width: 100%;
      max-width: 272px;
      border-left: 1px solid #dfdfdf; }
      @media only screen and (max-width: 1200px) {
        .category_catalog .filter_block.items .global_menu_block .li-catalog {
          width: 50%;
          max-width: 100%; } }
      .category_catalog .filter_block.items .global_menu_block .li-catalog .sub-menuCatalog {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center; }
      .category_catalog .filter_block.items .global_menu_block .li-catalog:hover::after {
        color: #9f98ad; }
      .category_catalog .filter_block.items .global_menu_block .li-catalog::after {
        display: inline-block;
        position: absolute;
        right: 25px;
        top: 15px;
        cursor: pointer; }
      .category_catalog .filter_block.items .global_menu_block .li-catalog .menu-icon {
        width: 20px;
        height: 12px;
        position: relative;
        cursor: pointer;
        margin: 0; }
        .category_catalog .filter_block.items .global_menu_block .li-catalog .menu-icon span {
          display: block;
          position: absolute;
          height: 2px;
          width: 100%;
          background: #454857;
          border-radius: 0;
          opacity: 1;
          left: 0;
          transform: rotate(0deg);
          transition: .3s ease-in-out; }
          .category_catalog .filter_block.items .global_menu_block .li-catalog .menu-icon span:nth-child(1) {
            top: 0; }
          .category_catalog .filter_block.items .global_menu_block .li-catalog .menu-icon span:nth-child(2), .category_catalog .filter_block.items .global_menu_block .li-catalog .menu-icon span:nth-child(3) {
            top: 5px; }
          .category_catalog .filter_block.items .global_menu_block .li-catalog .menu-icon span:nth-child(4) {
            top: 10px; }
        .category_catalog .filter_block.items .global_menu_block .li-catalog .menu-icon.active span:nth-child(1) {
          width: 0;
          top: 18px;
          left: 50%; }
        .category_catalog .filter_block.items .global_menu_block .li-catalog .menu-icon.active span:nth-child(2) {
          transform: rotate(45deg); }
        .category_catalog .filter_block.items .global_menu_block .li-catalog .menu-icon.active span:nth-child(3) {
          transform: rotate(-45deg); }
        .category_catalog .filter_block.items .global_menu_block .li-catalog .menu-icon.active span:nth-child(4) {
          width: 0;
          top: 18px;
          left: 50%; }
    .category_catalog .filter_block.items .global_menu_block .li-sezon,
    .category_catalog .filter_block.items .global_menu_block .li-razmer,
    .category_catalog .filter_block.items .global_menu_block .li-cena,
    .category_catalog .filter_block.items .global_menu_block .li-sort {
      width: 100%;
      position: relative;
      border-top: 1px solid transparent; }
      @media only screen and (max-width: 1200px) {
        .category_catalog .filter_block.items .global_menu_block .li-sezon,
        .category_catalog .filter_block.items .global_menu_block .li-razmer,
        .category_catalog .filter_block.items .global_menu_block .li-cena,
        .category_catalog .filter_block.items .global_menu_block .li-sort {
          width: 50%;
          display: flex;
          flex-direction: column;
          height: auto;
          padding: 0;
          border-top: none; } }
      .category_catalog .filter_block.items .global_menu_block .li-sezon:hover::after,
      .category_catalog .filter_block.items .global_menu_block .li-razmer:hover::after,
      .category_catalog .filter_block.items .global_menu_block .li-cena:hover::after,
      .category_catalog .filter_block.items .global_menu_block .li-sort:hover::after {
        color: #9f98ad; }
      @media only screen and (max-width: 1200px) {
        .category_catalog .filter_block.items .global_menu_block .li-sezon > a,
        .category_catalog .filter_block.items .global_menu_block .li-razmer > a,
        .category_catalog .filter_block.items .global_menu_block .li-cena > a,
        .category_catalog .filter_block.items .global_menu_block .li-sort > a {
          padding: 15px 10px 15px 25px;
          border: 1px solid #dfdfdf;
          border-top: none; } }
      @media only screen and (max-width: 1024px) {
        .category_catalog .filter_block.items .global_menu_block .li-sezon > a,
        .category_catalog .filter_block.items .global_menu_block .li-razmer > a,
        .category_catalog .filter_block.items .global_menu_block .li-cena > a,
        .category_catalog .filter_block.items .global_menu_block .li-sort > a {
          padding: 15px; } }
      .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"],
      .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"],
      .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"],
      .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] {
        display: none;
        position: absolute;
        margin: 0 auto;
        top: 50px;
        left: -1px; }
        @media only screen and (max-width: 1200px) {
          .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"],
          .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"],
          .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"],
          .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] {
            position: relative;
            top: 0;
            left: 0; } }
        .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range,
        .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range,
        .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range,
        .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range {
          display: flex;
          flex-direction: column;
          margin: 0;
          height: auto; }
          @media only screen and (max-width: 1200px) {
            .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range,
            .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range,
            .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range,
            .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range {
              width: 100%; } }
          .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.relation_block,
          .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.relation_block,
          .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.relation_block,
          .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.relation_block {
            background-color: #f8f8f8;
            padding: 0; }
            .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.relation_block .row,
            .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.relation_block .row,
            .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.relation_block .row,
            .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.relation_block .row {
              margin: 0; }
            .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.relation_block .form-group,
            .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.relation_block .form-group,
            .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.relation_block .form-group,
            .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.relation_block .form-group {
              padding: 0;
              margin: 0;
              display: flex;
              width: 100%;
              height: 100%; }
              .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.relation_block .form-group > div,
              .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.relation_block .form-group > div,
              .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.relation_block .form-group > div,
              .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.relation_block .form-group > div {
                width: 100%;
                padding: 0;
                border-bottom: 1px solid #dfdfdf; }
              .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.relation_block .form-group:last-child > div,
              .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.relation_block .form-group:last-child > div,
              .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.relation_block .form-group:last-child > div,
              .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.relation_block .form-group:last-child > div {
                border-bottom: none; }
              .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.relation_block .form-group .checkbox,
              .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.relation_block .form-group .checkbox,
              .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.relation_block .form-group .checkbox,
              .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.relation_block .form-group .checkbox {
                position: relative;
                padding-left: 0; }
                .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.relation_block .form-group .checkbox label,
                .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.relation_block .form-group .checkbox label,
                .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.relation_block .form-group .checkbox label,
                .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.relation_block .form-group .checkbox label {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  position: relative;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  padding: 15px 10px 15px 25px;
                  margin: 0; }
                  .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.relation_block .form-group .checkbox label::before,
                  .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.relation_block .form-group .checkbox label::before,
                  .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.relation_block .form-group .checkbox label::before,
                  .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.relation_block .form-group .checkbox label::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                    bottom: 0;
                    left: 25px;
                    max-width: 15px;
                    max-height: 15px;
                    border: 1px solid #dfdfdf;
                    -webkit-transition: all 0.5s ease;
                    -moz-transition: all 0.5s ease;
                    -ms-transition: all 0.5s ease;
                    -o-transition: all 0.5s ease;
                    transition: all 0.5s ease; }
                  .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.relation_block .form-group .checkbox label::after,
                  .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.relation_block .form-group .checkbox label::after,
                  .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.relation_block .form-group .checkbox label::after,
                  .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.relation_block .form-group .checkbox label::after {
                    content: '';
                    position: absolute;
                    top: calc(50% - 7px);
                    right: 0;
                    bottom: 0;
                    left: 31px;
                    max-width: 18px;
                    max-height: 8px;
                    border-left: 1px solid #000000;
                    border-bottom: 1px solid #000000;
                    opacity: 0;
                    transform: rotate(-45deg) translateY(-50%);
                    -webkit-transition: all 0.5s ease;
                    -moz-transition: all 0.5s ease;
                    -ms-transition: all 0.5s ease;
                    -o-transition: all 0.5s ease;
                    transition: all 0.5s ease; }
                  .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.relation_block .form-group .checkbox label.active::before,
                  .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.relation_block .form-group .checkbox label.active::before,
                  .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.relation_block .form-group .checkbox label.active::before,
                  .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.relation_block .form-group .checkbox label.active::before {
                    border: 1px solid #000000; }
                  .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.relation_block .form-group .checkbox label.active::after,
                  .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.relation_block .form-group .checkbox label.active::after,
                  .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.relation_block .form-group .checkbox label.active::after,
                  .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.relation_block .form-group .checkbox label.active::after {
                    opacity: 1; }
                .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.relation_block .form-group .checkbox input,
                .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.relation_block .form-group .checkbox input,
                .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.relation_block .form-group .checkbox input,
                .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.relation_block .form-group .checkbox input {
                  opacity: 0;
                  margin: 0; }
                .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.relation_block .form-group .checkbox span,
                .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.relation_block .form-group .checkbox span,
                .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.relation_block .form-group .checkbox span,
                .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.relation_block .form-group .checkbox span {
                  padding-left: 15px; }
          .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.price_block,
          .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.price_block,
          .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.price_block,
          .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.price_block {
            padding: 0; }
            .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.price_block .row,
            .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.price_block .row,
            .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.price_block .row,
            .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.price_block .row {
              margin: 0; }
              .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.price_block .row > div,
              .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.price_block .row > div,
              .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.price_block .row > div,
              .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.price_block .row > div {
                padding: 0; }
                .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.price_block .row > div:last-child .input-group,
                .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.price_block .row > div:last-child .input-group,
                .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.price_block .row > div:last-child .input-group,
                .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.price_block .row > div:last-child .input-group {
                  border: none; }
            .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.price_block .input-group,
            .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.price_block .input-group,
            .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.price_block .input-group,
            .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.price_block .input-group {
              margin: 0;
              border-bottom: 1px solid #dfdfdf; }
              .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.price_block .input-group .input-group-addon,
              .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.price_block .input-group input,
              .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.price_block .input-group .input-group-addon,
              .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.price_block .input-group input,
              .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.price_block .input-group .input-group-addon,
              .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.price_block .input-group input,
              .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.price_block .input-group .input-group-addon,
              .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.price_block .input-group input {
                height: auto;
                border: none;
                box-shadow: none;
                font-family: "Montserrat", sans-serif;
                font-weight: 600;
                font-style: normal;
                font-size: 13px;
                background-color: #f8f8f8; }
              .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.price_block .input-group .input-group-addon,
              .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.price_block .input-group .input-group-addon,
              .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.price_block .input-group .input-group-addon,
              .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.price_block .input-group .input-group-addon {
                min-width: 60px;
                position: relative;
                padding: 15px 15px 15px 25px; }
                .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.price_block .input-group .input-group-addon::before,
                .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.price_block .input-group .input-group-addon::before,
                .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.price_block .input-group .input-group-addon::before,
                .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.price_block .input-group .input-group-addon::before {
                  content: '';
                  position: absolute;
                  top: 10px;
                  right: 0;
                  bottom: 10px;
                  left: 0;
                  border-right: 1px solid #dfdfdf; }
              .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.price_block .input-group input,
              .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.price_block .input-group input,
              .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.price_block .input-group input,
              .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.price_block .input-group input {
                padding: 15px; }
          .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.padding-box,
          .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.padding-box,
          .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.padding-box,
          .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.padding-box {
            display: flex;
            padding: 0;
            position: relative; }
            .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.padding-box::before,
            .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.padding-box::before,
            .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.padding-box::before,
            .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.padding-box::before {
              position: absolute;
              content: '';
              top: -1px;
              right: -1px;
              bottom: -1px;
              left: -1px;
              background-color: #b2b2b2; }
            .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"] .block-range .box.padding-box input,
            .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] .block-range .box.padding-box input,
            .category_catalog .filter_block.items .global_menu_block .li-cena > [class^="li-"] .block-range .box.padding-box input,
            .category_catalog .filter_block.items .global_menu_block .li-sort > [class^="li-"] .block-range .box.padding-box input {
              position: inherit;
              text-transform: none;
              border: none;
              background-color: #b2b2b2; }
      .category_catalog .filter_block.items .global_menu_block .li-sezon [class^="toggle_list_"],
      .category_catalog .filter_block.items .global_menu_block .li-razmer [class^="toggle_list_"],
      .category_catalog .filter_block.items .global_menu_block .li-cena [class^="toggle_list_"],
      .category_catalog .filter_block.items .global_menu_block .li-sort [class^="toggle_list_"] {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0; }
        .category_catalog .filter_block.items .global_menu_block .li-sezon [class^="toggle_list_"]::after,
        .category_catalog .filter_block.items .global_menu_block .li-razmer [class^="toggle_list_"]::after,
        .category_catalog .filter_block.items .global_menu_block .li-cena [class^="toggle_list_"]::after,
        .category_catalog .filter_block.items .global_menu_block .li-sort [class^="toggle_list_"]::after {
          font-family: Flaticon;
          content: "\f104";
          font-size: 20px;
          font-weight: 600;
          display: inline-block;
          position: absolute;
          right: 20px;
          top: 10px;
          cursor: pointer;
          -webkit-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          transition: all 0.5s ease; }
        .category_catalog .filter_block.items .global_menu_block .li-sezon [class^="toggle_list_"].active::after,
        .category_catalog .filter_block.items .global_menu_block .li-razmer [class^="toggle_list_"].active::after,
        .category_catalog .filter_block.items .global_menu_block .li-cena [class^="toggle_list_"].active::after,
        .category_catalog .filter_block.items .global_menu_block .li-sort [class^="toggle_list_"].active::after {
          transform: rotate(-180deg); }
    .category_catalog .filter_block.items .global_menu_block .li-sezon,
    .category_catalog .filter_block.items .global_menu_block .li-razmer {
      max-width: 140px;
      min-width: 140px; }
      @media only screen and (max-width: 1200px) {
        .category_catalog .filter_block.items .global_menu_block .li-sezon,
        .category_catalog .filter_block.items .global_menu_block .li-razmer {
          max-width: 100%;
          min-width: 100%; } }
      .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"],
      .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] {
        width: 141px; }
        @media only screen and (min-width: 1200px) {
          .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"]::before,
          .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"]::before {
            content: '';
            position: absolute;
            top: -51px;
            right: 0;
            left: 0;
            bottom: 0;
            border: 1px solid #dfdfdf;
            border-top: none;
            z-index: -1;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
            opacity: 0;
            -webkit-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -ms-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            transition: all 0.5s ease; }
          .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"]::after,
          .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"]::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            border-right: 1px solid #dfdfdf;
            border-left: 1px solid #dfdfdf;
            z-index: -1; }
          .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"].active::before,
          .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"].active::before {
            opacity: 1; } }
        @media only screen and (max-width: 1200px) {
          .category_catalog .filter_block.items .global_menu_block .li-sezon > [class^="li-"],
          .category_catalog .filter_block.items .global_menu_block .li-razmer > [class^="li-"] {
            width: 100%; } }
    .category_catalog .filter_block.items .global_menu_block .li-cena {
      max-width: 270px;
      min-width: 270px !important; }
      @media only screen and (max-width: 1200px) {
        .category_catalog .filter_block.items .global_menu_block .li-cena {
          max-width: 100%;
          min-width: 100% !important; } }
      .category_catalog .filter_block.items .global_menu_block .li-cena .li-price-item_lines {
        width: 271px; }
        @media only screen and (min-width: 1200px) {
          .category_catalog .filter_block.items .global_menu_block .li-cena .li-price-item_lines::before {
            content: '';
            position: absolute;
            top: -51px;
            right: 0;
            left: 0;
            bottom: 0;
            border: 1px solid #dfdfdf;
            border-top: none;
            z-index: -1;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
            opacity: 0;
            -webkit-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -ms-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            transition: all 0.5s ease; }
          .category_catalog .filter_block.items .global_menu_block .li-cena .li-price-item_lines::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            border-right: 1px solid #dfdfdf;
            border-left: 1px solid #dfdfdf;
            z-index: -1; }
          .category_catalog .filter_block.items .global_menu_block .li-cena .li-price-item_lines.active::before {
            opacity: 1; } }
        @media only screen and (max-width: 1200px) {
          .category_catalog .filter_block.items .global_menu_block .li-cena .li-price-item_lines {
            width: 100%; } }
      .category_catalog .filter_block.items .global_menu_block .li-cena > a .title {
        padding: 0 10px; }
      @media only screen and (max-width: 768px) {
        .category_catalog .filter_block.items .global_menu_block .li-cena > a {
          display: flex;
          flex-wrap: wrap; }
          .category_catalog .filter_block.items .global_menu_block .li-cena > a .title {
            padding: 0; }
          .category_catalog .filter_block.items .global_menu_block .li-cena > a span:first-child {
            width: 100%;
            padding-bottom: 10px; }
          .category_catalog .filter_block.items .global_menu_block .li-cena > a span:nth-child(2),
          .category_catalog .filter_block.items .global_menu_block .li-cena > a span:nth-child(4) {
            width: 20%; }
          .category_catalog .filter_block.items .global_menu_block .li-cena > a span:nth-child(3),
          .category_catalog .filter_block.items .global_menu_block .li-cena > a span:nth-child(5) {
            width: 80%; } }
    .category_catalog .filter_block.items .global_menu_block .li-sort {
      min-width: 40% !important;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media only screen and (max-width: 1200px) {
        .category_catalog .filter_block.items .global_menu_block .li-sort {
          width: 100%;
          border: 1px solid #dfdfdf;
          border-top: none; } }
      @media only screen and (max-width: 1024px) {
        .category_catalog .filter_block.items .global_menu_block .li-sort {
          align-items: flex-start; } }
      .category_catalog .filter_block.items .global_menu_block .li-sort a {
        width: 55%; }
        @media only screen and (max-width: 1200px) {
          .category_catalog .filter_block.items .global_menu_block .li-sort a {
            border: none; } }
        @media only screen and (max-width: 768px) {
          .category_catalog .filter_block.items .global_menu_block .li-sort a span:first-child {
            font-size: 11px; } }
        .category_catalog .filter_block.items .global_menu_block .li-sort a > [class^="toggle_list_"]::after {
          font-family: Flaticon;
          content: "\f104";
          font-size: 20px;
          font-weight: 600;
          display: inline-block;
          position: absolute;
          right: 20px;
          top: 10px;
          cursor: pointer;
          -webkit-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          transition: all 0.5s ease; }
        .category_catalog .filter_block.items .global_menu_block .li-sort a > [class^="toggle_list_"].active::after {
          transform: rotate(-180deg); }
      .category_catalog .filter_block.items .global_menu_block .li-sort > .li-sort-item_lines {
        display: none;
        width: calc(100% + 2px);
        background-color: #f8f8f8;
        border-top: 0; }
        @media only screen and (min-width: 1200px) {
          .category_catalog .filter_block.items .global_menu_block .li-sort > .li-sort-item_lines::before {
            content: '';
            position: absolute;
            top: -51px;
            right: 0;
            left: 0;
            bottom: 0;
            border: 1px solid #dfdfdf;
            border-top: none;
            z-index: -1;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
            opacity: 0;
            -webkit-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -ms-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            transition: all 0.5s ease; }
          .category_catalog .filter_block.items .global_menu_block .li-sort > .li-sort-item_lines::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            border-right: 1px solid #dfdfdf;
            border-left: 1px solid #dfdfdf;
            z-index: -1; }
          .category_catalog .filter_block.items .global_menu_block .li-sort > .li-sort-item_lines.active::before {
            opacity: 1; } }
        @media only screen and (max-width: 1200px) {
          .category_catalog .filter_block.items .global_menu_block .li-sort > .li-sort-item_lines {
            width: 100%;
            border: none;
            border-top: 1px solid #dfdfdf; } }
        .category_catalog .filter_block.items .global_menu_block .li-sort > .li-sort-item_lines span {
          font-family: "Montserrat", sans-serif;
          font-weight: 600;
          font-style: normal;
          font-size: 13px;
          padding: 15px 15px 15px 25px;
          border-bottom: 1px solid #dfdfdf;
          -webkit-transition: color .5s ease;
          -moz-transition: color .5s ease;
          -ms-transition: color .5s ease;
          -o-transition: color .5s ease;
          transition: color .5s ease; }
          .category_catalog .filter_block.items .global_menu_block .li-sort > .li-sort-item_lines span:last-child {
            border: none; }
          .category_catalog .filter_block.items .global_menu_block .li-sort > .li-sort-item_lines span:hover {
            color: #9f98ad; }
        .category_catalog .filter_block.items .global_menu_block .li-sort > .li-sort-item_lines .block-range {
          padding: 1px 0; }
          @media only screen and (max-width: 1200px) {
            .category_catalog .filter_block.items .global_menu_block .li-sort > .li-sort-item_lines .block-range {
              border: none; } }
      .category_catalog .filter_block.items .global_menu_block .li-sort .sort-control {
        height: auto; }
    .category_catalog .filter_block.items .global_menu_block select {
      width: 100%;
      min-width: 170px;
      display: inline;
      position: relative;
      z-index: 22;
      padding: 0;
      padding-right: 30px;
      border: none;
      background: transparent;
      -webkit-appearance: none;
      -moz-appearance: none;
      direction: rtl;
      pointer-events: none;
      cursor: pointer;
      box-shadow: none; }
      @media only screen and (max-width: 1200px) {
        .category_catalog .filter_block.items .global_menu_block select {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          padding-right: 60px; } }
      @media only screen and (max-width: 1024px) {
        .category_catalog .filter_block.items .global_menu_block select {
          box-sizing: border-box;
          position: relative;
          direction: ltr;
          width: 100%;
          min-width: inherit;
          padding: 0;
          padding-left: 15px;
          font-size: 12px; } }
      .category_catalog .filter_block.items .global_menu_block select:focus {
        background-color: transparent !important; }
      .category_catalog .filter_block.items .global_menu_block select option {
        background-color: #f8f8f8; }
    .category_catalog .filter_block.items .global_menu_block .li-filter-button {
      display: none;
      border-right: 1px solid #dfdfdf;
      border-left: 1px solid #dfdfdf; }
      .category_catalog .filter_block.items .global_menu_block .li-filter-button > a {
        width: 100%;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        align-items: center; }
      .category_catalog .filter_block.items .global_menu_block .li-filter-button .menu-icon {
        width: 20px;
        height: 12px;
        position: relative;
        cursor: pointer;
        margin: 0; }
        .category_catalog .filter_block.items .global_menu_block .li-filter-button .menu-icon span {
          display: block;
          position: absolute;
          background: #454857;
          opacity: 1;
          left: 0;
          transform: rotate(0deg);
          transition: .3s ease-in-out;
          width: 3px;
          height: 3px;
          border-radius: 50%; }
          .category_catalog .filter_block.items .global_menu_block .li-filter-button .menu-icon span:nth-child(1) {
            top: 0;
            left: 15px; }
          .category_catalog .filter_block.items .global_menu_block .li-filter-button .menu-icon span:nth-child(2) {
            top: 0; }
          .category_catalog .filter_block.items .global_menu_block .li-filter-button .menu-icon span:nth-child(3) {
            top: 10px; }
          .category_catalog .filter_block.items .global_menu_block .li-filter-button .menu-icon span:nth-child(4) {
            top: 10px;
            left: 15px; }
        .category_catalog .filter_block.items .global_menu_block .li-filter-button .menu-icon.active span {
          width: 100%;
          height: 2px;
          border-radius: 0; }
          .category_catalog .filter_block.items .global_menu_block .li-filter-button .menu-icon.active span:nth-child(1) {
            left: 0;
            top: 5px;
            transform: rotate(-45deg); }
          .category_catalog .filter_block.items .global_menu_block .li-filter-button .menu-icon.active span:nth-child(2) {
            top: 5px;
            left: 0;
            transform: rotate(45deg); }
          .category_catalog .filter_block.items .global_menu_block .li-filter-button .menu-icon.active span:nth-child(3) {
            top: 5px;
            transform: rotate(-45deg); }
          .category_catalog .filter_block.items .global_menu_block .li-filter-button .menu-icon.active span:nth-child(4) {
            left: 0;
            top: 5px;
            transform: rotate(45deg); }
      @media only screen and (max-width: 1200px) {
        .category_catalog .filter_block.items .global_menu_block .li-filter-button {
          display: flex; } }
    .category_catalog .filter_block.items .global_menu_block .li-filter {
      display: flex; }
      @media only screen and (max-width: 1200px) {
        .category_catalog .filter_block.items .global_menu_block .li-filter {
          width: 50%;
          flex-direction: column; } }
    .category_catalog .filter_block.items .global_menu_block .li-wrapper {
      display: flex; }
      @media only screen and (max-width: 1200px) {
        .category_catalog .filter_block.items .global_menu_block .li-wrapper {
          display: none;
          position: absolute;
          top: 51px;
          z-index: 98;
          padding: 0;
          width: 100%;
          max-width: 50%;
          margin: 0;
          border-top: none;
          border-bottom: none;
          list-style: none;
          background-color: #f8f8f8;
          box-sizing: border-box; } }
      .category_catalog .filter_block.items .global_menu_block .li-wrapper::before {
        content: '';
        position: absolute;
        top: -51px;
        right: 0;
        left: 0;
        bottom: 0;
        border: 1px solid #dfdfdf;
        border-top: none;
        z-index: -1;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
        opacity: 0;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease; }
      .category_catalog .filter_block.items .global_menu_block .li-wrapper::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        border-right: 1px solid #dfdfdf;
        border-left: 1px solid #dfdfdf;
        z-index: -1; }
      .category_catalog .filter_block.items .global_menu_block .li-wrapper.active::before {
        opacity: 1; }
  .category_catalog .filter_block.items .level1 {
    display: none;
    position: absolute;
    top: 51px;
    z-index: 98;
    padding: 0;
    margin-right: 20px;
    margin-bottom: 0;
    width: 100%;
    max-width: calc(25% - 21px);
    border-top: none;
    border-bottom: none;
    list-style: none;
    background-color: #f8f8f8;
    box-sizing: border-box; }
    @media only screen and (max-width: 1200px) {
      .category_catalog .filter_block.items .level1 {
        width: 100%;
        max-width: 50%;
        margin: 0; } }
    .category_catalog .filter_block.items .level1::before {
      content: '';
      position: absolute;
      top: -51px;
      right: 0;
      left: 0;
      bottom: 0;
      border: 1px solid #dfdfdf;
      border-top: none;
      z-index: -1;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
      opacity: 0;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -ms-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease; }
    .category_catalog .filter_block.items .level1::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      border-right: 1px solid #dfdfdf;
      border-left: 1px solid #dfdfdf;
      z-index: -1; }
    .category_catalog .filter_block.items .level1.active::before {
      opacity: 1; }
    .category_catalog .filter_block.items .level1 li {
      font-size: 13px; }
    .category_catalog .filter_block.items .level1 .top-menu__item {
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: 0;
      cursor: pointer; }
      .category_catalog .filter_block.items .level1 .top-menu__item > a {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 25px;
        border-bottom: 1px solid #dfdfdf;
        font-weight: 600;
        pointer-events: none; }
        @media only screen and (max-width: 768px) {
          .category_catalog .filter_block.items .level1 .top-menu__item > a {
            padding: 15px 30px 15px 15px; } }
        .category_catalog .filter_block.items .level1 .top-menu__item > a::after {
          font-family: Flaticon;
          content: "\f104";
          font-size: 20px;
          display: inline-block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 20px;
          cursor: pointer;
          -webkit-transition: all .5s ease;
          -moz-transition: all .5s ease;
          -ms-transition: all .5s ease;
          -o-transition: all .5s ease;
          transition: all .5s ease; }
        .category_catalog .filter_block.items .level1 .top-menu__item > a.active::after {
          transform: translateY(-50%) rotate(-180deg); }
      .category_catalog .filter_block.items .level1 .top-menu__item a {
        text-decoration: none; }
      .category_catalog .filter_block.items .level1 .top-menu__item .top-sub-menu {
        padding: 10px 0;
        border-bottom: 1px solid #dfdfdf; }
        .category_catalog .filter_block.items .level1 .top-menu__item .top-sub-menu .top-sub-menu__item {
          list-style: none;
          padding: 10px 10px 10px 50px; }
          @media only screen and (max-width: 768px) {
            .category_catalog .filter_block.items .level1 .top-menu__item .top-sub-menu .top-sub-menu__item {
              padding: 10px 20px; } }
      .category_catalog .filter_block.items .level1 .top-menu__item .sub-block {
        display: none; }

.category_catalog__one-product .global_menu_block {
  justify-content: space-between !important; }
  @media only screen and (max-width: 1200px) {
    .category_catalog__one-product .global_menu_block li {
      border-right: 1px solid #dfdfdf !important; } }
  .category_catalog__one-product .global_menu_block .back-button {
    max-width: 285px;
    text-align: right;
    border-left: 1px solid #dfdfdf; }
    .category_catalog__one-product .global_menu_block .back-button a {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0 20px;
      width: 100%;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 13px; }
    .category_catalog__one-product .global_menu_block .back-button::before {
      font-family: Flaticon;
      content: "\f104";
      position: absolute;
      top: 50%;
      left: 25px;
      font-size: 20px;
      font-weight: 600;
      cursor: pointer;
      transition: all .5s ease;
      transform: translateY(-50%) rotate(90deg); }
      @media only screen and (max-width: 768px) {
        .category_catalog__one-product .global_menu_block .back-button::before {
          display: none; } }
    .category_catalog__one-product .global_menu_block .back-button:hover a {
      text-decoration: none;
      color: #9f98ad !important; }

.category_catalog__one-product .menu_and_filters_block .filter_block_line {
  padding: 0 15px; }
  @media only screen and (max-width: 1024px) {
    .category_catalog__one-product .menu_and_filters_block .filter_block_line {
      padding: 0; } }

.category_catalog__one-product .filter_block.items .level1 {
  margin: 0;
  max-width: 25%; }
  @media only screen and (max-width: 1200px) {
    .category_catalog__one-product .filter_block.items .level1 {
      width: 100%;
      max-width: 285px !important; } }
  @media only screen and (max-width: 768px) {
    .category_catalog__one-product .filter_block.items .level1 {
      width: 100%;
      max-width: 50% !important; } }

.category_catalog__one-product .filter_block.items .global_menu_block .li-catalog {
  max-width: 285px; }
  @media only screen and (max-width: 768px) {
    .category_catalog__one-product .filter_block.items .global_menu_block .li-catalog {
      max-width: 50%; } }

@media only screen and (max-width: 768px) {
  .category_catalog__one-product .filter_block.items .global_menu_block .back-button {
    max-width: 50%;
    border-left: none; } }

.category_catalog__one-product label {
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 13px !important; }

.category_catalog__one-product .vibor_razmera {
  position: relative;
  padding: 25px 0;
  margin-left: 0;
  margin-bottom: 25px;
  border-bottom: 1px solid #dfdfdf; }
  .category_catalog__one-product .vibor_razmera .field {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0; }
    .category_catalog__one-product .vibor_razmera .field label {
      width: 100%;
      padding: 0;
      margin: 0 0 25px; }
    .category_catalog__one-product .vibor_razmera .field > .field-value {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%; }
      .category_catalog__one-product .vibor_razmera .field > .field-value .ref-link {
        min-width: 150px;
        text-align: center;
        position: relative;
        display: flex;
        flex-direction: column;
        top: -45px;
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        font-style: normal;
        font-size: 13px !important;
        color: #454857;
        -webkit-transition: color .5s ease;
        -moz-transition: color .5s ease;
        -ms-transition: color .5s ease;
        -o-transition: color .5s ease;
        transition: color .5s ease; }
        .category_catalog__one-product .vibor_razmera .field > .field-value .ref-link__text {
          display: none; }
          .category_catalog__one-product .vibor_razmera .field > .field-value .ref-link__text.active {
            display: block; }
        .category_catalog__one-product .vibor_razmera .field > .field-value .ref-link:hover {
          color: #9f98ad; }

#bg-banner {
  border-bottom: 1px solid #dfdfdf;
  position: relative;
  z-index: 10; }
  #bg-banner .block.footer-block {
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(248, 248, 248, 0.8)), to(rgba(248, 248, 248, 0.8))), url(/images/header_bg.png);
    background: -webkit-linear-gradient(rgba(248, 248, 248, 0.8), rgba(248, 248, 248, 0.8)), url(/images/header_bg.png);
    background: -o-linear-gradient(rgba(248, 248, 248, 0.8), rgba(248, 248, 248, 0.8)), url(/images/header_bg.png);
    background: linear-gradient(rgba(248, 248, 248, 0.8), rgba(248, 248, 248, 0.8)), url(/images/header_bg.png);
    background-size: 100%;
    background-position-y: -75px;
    padding: 30px; }
    #bg-banner .block.footer-block .header-1 {
      margin-bottom: 0; }
      #bg-banner .block.footer-block .header-1 h1 {
        font-family: "Romul", sans-serif;
        margin: 0 auto; }

#table-of-sizes {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  transform: translateY(0);
  background-color: #f8f8f8;
  padding: 0 15px;
  margin: 0;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
  z-index: 999; }
  #table-of-sizes table {
    width: 100%;
    max-width: 100%; }
    #table-of-sizes table tr {
      display: flex;
      justify-content: flex-start;
      border-color: #d0aa8c; }
    #table-of-sizes table td {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: "Montserrat", sans-serif;
      color: #454857;
      padding: 15px 0; }
    #table-of-sizes table thead td {
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-style: normal; }
    #table-of-sizes table tbody tr:last-child {
      border: none; }
    #table-of-sizes table tbody td {
      width: 30px; }
      #table-of-sizes table tbody td:first-child {
        width: 100px;
        margin-right: 50px;
        align-items: flex-start; }

.modal .modal-dialog {
  position: fixed;
  overflow: inherit;
  top: 40%;
  left: 50%;
  margin: 0;
  min-width: 400px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%) !important; }
  @media only screen and (max-width: 480px) {
    .modal .modal-dialog {
      left: 0;
      margin: 0 auto;
      width: 100%;
      min-width: 100%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(0, -50%) !important; } }

.modal-backdrop {
  z-index: 0; }

.product-detail-section .related-products {
  padding: 35px 0;
  margin: 0;
  border-top: 1px solid #dfdfdf;
  font-family: "Romul", sans-serif;
  font-weight: 400;
  font-size: 26px; }
  .product-detail-section .related-products::before, .product-detail-section .related-products::after {
    display: none; }

.product-detail-section .product-block {
  padding: 0;
  margin-bottom: 35px;
  min-height: inherit;
  background-color: #f8f8f8;
  border: none; }
  .product-detail-section .product-block .tab-content {
    position: relative;
    display: flex;
    border-top: 1px solid #dfdfdf; }
    @media only screen and (max-width: 1024px) {
      .product-detail-section .product-block .tab-content {
        justify-content: center; } }
    @media only screen and (max-width: 768px) {
      .product-detail-section .product-block .tab-content {
        justify-content: space-around; } }
    @media only screen and (max-width: 480px) {
      .product-detail-section .product-block .tab-content {
        flex-direction: column;
        justify-content: center;
        align-items: center; } }
    .product-detail-section .product-block .tab-content .tab-pane {
      position: relative;
      margin-right: 85px;
      cursor: pointer; }
      .product-detail-section .product-block .tab-content .tab-pane::before {
        display: none;
        content: '';
        position: absolute;
        z-index: 0;
        width: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-bottom: 1px solid #dfdfdf; }
      @media only screen and (max-width: 1024px) {
        .product-detail-section .product-block .tab-content .tab-pane {
          margin: 0 40px; } }
      @media only screen and (max-width: 768px) {
        .product-detail-section .product-block .tab-content .tab-pane {
          margin: 0 auto; } }
      @media only screen and (max-width: 576px) {
        .product-detail-section .product-block .tab-content .tab-pane {
          margin: 0;
          margin-right: auto; } }
      @media only screen and (max-width: 480px) {
        .product-detail-section .product-block .tab-content .tab-pane {
          width: 100%;
          margin: 0 auto; }
          .product-detail-section .product-block .tab-content .tab-pane::before {
            display: block; } }
      @media only screen and (max-width: 576px) {
        .product-detail-section .product-block .tab-content .tab-pane:last-child {
          margin-right: 0; } }
      @media only screen and (max-width: 480px) {
        .product-detail-section .product-block .tab-content .tab-pane:last-child {
          margin: 0 auto; }
          .product-detail-section .product-block .tab-content .tab-pane:last-child::before {
            display: none; } }
      .product-detail-section .product-block .tab-content .tab-pane.active {
        pointer-events: none; }
        .product-detail-section .product-block .tab-content .tab-pane.active h3 {
          color: #d0aa8c; }
          .product-detail-section .product-block .tab-content .tab-pane.active h3::before {
            left: 0;
            right: 0; }
      .product-detail-section .product-block .tab-content .tab-pane:hover h3 {
        color: #d0aa8c; }
        .product-detail-section .product-block .tab-content .tab-pane:hover h3::before {
          left: 0;
          right: 0; }
    .product-detail-section .product-block .tab-content h3 {
      position: relative;
      padding: 20px 0;
      margin: 0;
      border: none;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-style: normal;
      text-align: center;
      font-size: 13px;
      letter-spacing: .5px;
      -webkit-transition: all .5s ease;
      -moz-transition: all .5s ease;
      -ms-transition: all .5s ease;
      -o-transition: all .5s ease;
      transition: all .5s ease; }
      @media only screen and (max-width: 480px) {
        .product-detail-section .product-block .tab-content h3 {
          padding: 20px 0 5px; } }
      .product-detail-section .product-block .tab-content h3::before {
        content: '';
        position: absolute;
        top: calc(100% - 2px);
        left: 50%;
        bottom: 0;
        right: 50%;
        background-color: #d0aa8c;
        z-index: 1;
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
        -ms-transition: all .5s ease;
        -o-transition: all .5s ease;
        transition: all .5s ease; }
  .product-detail-section .product-block .slide-js__item {
    display: none;
    position: relative;
    width: 100%;
    border-top: 1px solid #dfdfdf;
    padding: 35px 0 0; }
    .product-detail-section .product-block .slide-js__item p {
      padding: 5px 0;
      margin: 0;
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 13px; }
    .product-detail-section .product-block .slide-js__item.active {
      display: block; }

#select-box span:first-child {
  display: none; }

.warning-size {
  display: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: red;
  margin-top: 10px; }

.block-pagination .pagination li a {
  font: 500 17px "Montserrat", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0 10px; }

.block-pagination .pagination li .flaticon-right-arrow,
.block-pagination .pagination li .flaticon-left-arrow {
  color: #454857; }

.block-pagination .pagination li:hover a {
  background-color: #d0aa8c;
  border-color: #d0aa8c;
  color: #454857; }

.block-pagination .pagination li:hover .flaticon-right-arrow,
.block-pagination .pagination li:hover .flaticon-left-arrow {
  color: #454857; }

.block-pagination .pagination li.active a {
  pointer-events: none; }
