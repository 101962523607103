#bg-banner {
    border-bottom: 1px solid $border-color;
    position: relative;
    z-index: 10;

    .block {
        &.footer-block {
            background: -webkit-gradient(linear, left top, left bottom, from(rgba($white-gray, 0.8)), to(rgba($white-gray, 0.8))), url(/images/header_bg.png);
            background: -webkit-linear-gradient(rgba($white-gray, 0.8), rgba($white-gray, 0.8)), url(/images/header_bg.png);
            background: -o-linear-gradient(rgba($white-gray, 0.8), rgba($white-gray, 0.8)), url(/images/header_bg.png);
            background: linear-gradient(rgba($white-gray, 0.8), rgba($white-gray, 0.8)), url(/images/header_bg.png);
            background-size: 100%;
            background-position-y: -75px;
            padding: 30px;

            & .header-1 {
                margin-bottom: 0;

                h1 {
                    font-family: $font_r;
                    margin: 0 auto;
                }
            }
        }
    }
}