#table-of-sizes {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    transform: translateY(0);
    background-color: $white-gray;
    padding: 0 15px;
    margin: 0;
    box-shadow: 0 0 15px 0 rgba($black, .25);
    z-index: 999;

    table {
        width: 100%;
        max-width: 100%;

        tr {
            display: flex;
            justify-content: flex-start;
            border-color: $gold;
        }

        td {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-family: $font_m;
            color: $blue;
            padding: 15px 0;
        }

        thead td {
            text-transform: uppercase;
            font-family: $font_m;
            font-weight: 600;
            font-style: normal;
        }

        tbody {
            tr {
                &:last-child {
                    border: none
                }
            }

            td {
                width: 30px;

                &:first-child {
                    width: 100px;
                    margin-right: 50px;
                    align-items: flex-start;
                }
            }
        }
    }
}

.modal {
    .modal-dialog {
        position: fixed;
        overflow: inherit;
        top: 40%;
        left: 50%;
        margin: 0;
        min-width: 400px;

        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%) !important;

        @include max-screen(480px) {
            left: 0;
            margin: 0 auto;
            width: 100%;
            min-width: 100%;

            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(0, -50%) !important;
        }
    }
}

//Cover
.modal-backdrop {
    z-index: 0;
}