#header {
    .header-main,
    .header-main-fixed {
        width: 100%;
        z-index: 999;
        background: $black; /* Old browsers */
        background: -moz-linear-gradient(left, $black 0%, $blue 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, $black 0%, $blue 100%); /* Chrome10-25, Safari5.1-6 */
        background: linear-gradient(to right, $black 0%, $blue 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

        .container {
            padding: 0;

            @include max-screen(992px) {
                width: 100%;
                padding: 0 30px;
            }
        }

        @include min-screen(1024px) {
            position: fixed;
        }
    }

    + section {
        @include min-screen(1024px) {
            padding-top: 80px;
        }
    }

    .header-logo {
        display: block;
        width: 100%;
        min-width: 210px;
        height: 100%;
        min-height: 80px;
        box-sizing: border-box;
        background-repeat: no-repeat;
        -webkit-background-size: contain;
        background-size: contain;
        background-position: 50% 50%;

        @include max-screen(576px) {
            width: 50vw;
            min-width: inherit;
        }
    }

    p, a, span {
        font-family: 'Romul', sans-serif;
        color: $white;
    }

    a {
        text-decoration: none;
    }

    p {
        margin: 0;
    }

    .navbar {
        display: flex;

        @include max-screen(992px) {
            display: none;
        }

        & + .header-separator {
            @include max-screen(1200px) {
                display: none;
            }
        }
    }

    nav {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        margin: 0;

        ul {
            display: flex;
            padding: 0;
            margin: 0;
        }

        li,
        li.active {
            font-family: Romul, sans-serif;
            font-size: 13px;
            letter-spacing: .5px;
            list-style: none;
            text-transform: uppercase;

            &.active {
                color: $gold;
            }
        }

        li {
            &:hover a {
                color: $gold;
            }

            &:last-child {
                .header-separator {
                    @include max-screen(992px) {
                        display: none;
                    }
                }
            }
        }
    }

    .social-link {
        display: flex;

        @include max-screen(1200px) {
            display: none;
        }

        .flaticon-instagram-logo {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: 15px;

            &::before {
                font-size: 23px;
                color: $gold;
            }
        }

        &__and {
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $gold;
            padding: 0 15px;
        }

        &__title {
            letter-spacing: 1.8px;
            text-transform: uppercase;

            -webkit-transition: color .5s ease;
            -moz-transition: color .5s ease;
            -ms-transition: color .5s ease;
            -o-transition: color .5s ease;
            transition: color .5s ease;
        }

        &__text {
            font-family: "Montserrat", sans-serif;
            font-weight: 500;
            font-size: 10px;
        }

        &__man,
        &__woman {
            display: flex;

            .wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                p {
                    line-height: 13px;
                }
            }

            &:hover .social-link__title {
                color: $gold;
            }
        }
    }

    .block-phones {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include max-screen(992px) {
            display: none;
        }

        a {
            font-size: 13px;
            padding: 2px 0;

            &:hover {
                color: $gold;
            }
        }
    }

    .login-col {
        display: flex;

        & > div {
            margin: 0;
        }
    }

    .block-search,
    .block-cart,
    .header-mini-cart {
        display: flex;
        justify-content: center;
        align-items: center;
        background: inherit !important;
    }

    .block-cart {
        a {
            position: relative;
            width: 100%;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;

            span {
                &:last-child {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 22px;
                    height: 22px;
                    font-family: "Montserrat", sans-serif;
                    font-weight: 700;
                    font-size: 11px;
                    top: 35%;
                    right: -2px;
                    transform: translateY(-50%);
                    position: absolute;
                    background-color: $white;
                    color: $blue;
                }
            }
        }
    }

    .flaticon-magnifying-glass,
    .flaticon-cancel {
        opacity: 1;

        &:before {
            color: $gold;
            font-size: 23px;
        }
    }

    .flaticon-shopping-bag {
        color: $gold !important;
        top: 0 !important;
        position: relative !important;
        width: auto !important;
        height: auto !important;
        line-height: inherit !important;
        border-radius: inherit !important;
        background: inherit !important;

        &:before {
            font-size: 28px;
        }
    }

    .header-mini-cart {
        height: auto;
        margin: 0;
    }

    .header-separator {
        position: relative;
        min-height: 80px;
        height: 100%;
        padding: 0 17px;

        &:before {
            position: absolute;
            content: '';
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 1px;
            height: 34px;
            background-color: rgba($white, .3);
        }
    }

    .navbar-mobile {
        display: none !important;
        margin-bottom: 20px;

        @include max-screen(992px) {
            display: flex !important;
        }

        ul {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        li {
            padding: 0 30px;

            .header-separator {
                display: none;
            }
        }
    }
}

#header-search {
    cursor: pointer;
    margin: 0;
}

#container-fixed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0 !important;

    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;

    @include max-screen(1200px) {
        justify-content: center;
    }

    & > div {
        padding: 0;

        &:first-child {
            & + .header-separator {
                @include max-screen(992px) {
                    display: none;
                }
            }
        }
    }
}
