.block-pagination {
    .pagination {
        li {
            a {
                font: 500 17px $font_m;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                padding: 0;
                margin: 0 10px;
            }

            .flaticon-right-arrow,
            .flaticon-left-arrow {
                color: $blue;
            }

            &:hover {
                a {
                    background-color: $gold;
                    border-color: $gold;
                    color: $blue;
                }

                .flaticon-right-arrow,
                .flaticon-left-arrow {
                    color: $blue;
                }
            }

            &.active {
                a {
                    pointer-events: none;
                }
            }
        }
    }
}