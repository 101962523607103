.block-search {
    .header-top-search {
        top: 80px !important;
        right: -17px !important;
        background-color: $white-gray !important;

        @include max-screen(992px) {
            top: 120px !important;
            right: -35px !important;
        }
    }

    .top-search-form {
        form {
            width: 100%;
            display: flex;
        }

        input,
        button {
            border: none;
            border-color: $white-gray;
            background-color: $white-gray;
        }

        input {
            font-size: 10px;
            font-family: "Montserrat", sans-serif;
            font-weight: 600;
            text-transform: uppercase;
            box-sizing: border-box;

            &:focus {
                outline: none;
            }
        }

        button {
            width: 60px !important;
            box-sizing: border-box;
            padding: 0 17px;
            border: none;
            border-left: 1px solid rgba($black, .2);

            &:hover {
                background-color: $gold;
                border: none;
                border-left: 1px solid rgba($black, .2);
            }

            i::before {
                font-size: 21px !important;
                color: $black !important;
                font-weight: 400 !important;
            }
        }
    }
}

#area {
    border: none !important;
}