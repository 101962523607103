.product-detail-section {
    .related-products {
        padding: 35px 0;
        margin: 0;
        border-top: 1px solid $border-color;
        font-family: $font_r;
        font-weight: 400;
        font-size: 26px;

        &::before,
        &::after {
            display: none;
        }
    }

    .product-block {
        padding: 0;
        margin-bottom: 35px;
        min-height: inherit;
        background-color: $white-gray;
        border: none;

        .tab-content {
            position: relative;
            display: flex;
            border-top: 1px solid $border-color;

            @include max-screen(1024px) {
                justify-content: center;
            }

            @include max-screen(768px) {
                justify-content: space-around;
            }

            @include max-screen(480px) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .tab-pane {
                position: relative;
                margin-right: 85px;
                cursor: pointer;

                &::before {
                    display: none;
                    content: '';
                    position: absolute;
                    z-index: 0;
                    width: 100%;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    border-bottom: 1px solid $border-color;
                }

                @include max-screen(1024px) {
                    margin: 0 40px;
                }

                @include max-screen(768px) {
                    margin: 0 auto;
                }

                @include max-screen(576px) {
                    margin: 0;
                    margin-right: auto;
                }

                @include max-screen(480px) {
                    width: 100%;
                    margin: 0 auto;

                    &::before {
                        display: block;
                    }
                }

                &:last-child {
                    @include max-screen(576px) {
                        margin-right: 0;
                    }

                    @include max-screen(480px) {
                        margin: 0 auto;

                        &::before {
                            display: none;
                        }
                    }
                }

                &.active {
                    pointer-events: none;

                    h3 {
                        color: $gold;

                        &::before {
                            left: 0;
                            right: 0;
                        }
                    }
                }

                &:hover {
                    h3 {
                        color: $gold;

                        &::before {
                            left: 0;
                            right: 0;
                        }
                    }
                }
            }

            h3 {
                position: relative;
                padding: 20px 0;
                margin: 0;
                border: none;
                text-transform: uppercase;
                font-family: $font_m;
                font-weight: 600;
                font-style: normal;
                text-align: center;
                font-size: 13px;
                letter-spacing: .5px;

                -webkit-transition: all .5s ease;
                -moz-transition: all .5s ease;
                -ms-transition: all .5s ease;
                -o-transition: all .5s ease;
                transition: all .5s ease;

                @include max-screen(480px) {
                    padding: 20px 0 5px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: calc(100% - 2px);
                    left: 50%;
                    bottom: 0;
                    right: 50%;
                    background-color: $gold;
                    z-index: 1;

                    -webkit-transition: all .5s ease;
                    -moz-transition: all .5s ease;
                    -ms-transition: all .5s ease;
                    -o-transition: all .5s ease;
                    transition: all .5s ease;
                }
            }
        }

        .slide-js {
            &__item {
                display: none;
                position: relative;
                width: 100%;
                border-top: 1px solid $border-color;
                padding: 35px 0 0;

                p {
                    padding: 5px 0;
                    margin: 0;
                    font-family: $font_m;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 13px;
                }

                &.active {
                    display: block;
                }
            }
        }
    }
}

#select-box {
    span:first-child {
        display: none;
    }
}

.warning-size {
    display: none;
    font-family: $font_m;
    font-weight: 500;
    color: red;
    margin-top: 10px;
}