.category_catalog {
    .menu_and_filters_block {
        position: relative;

        &::before {
            position: absolute;
            width: 100%;
            top: 100%;
            content: '';
            border-bottom: 1px solid $border-color;
        }

        .filter_block_line {
            padding: 0;
        }

        + .container {
            padding: 0;
            margin: 0 auto;
            box-sizing: border-box;

            .row {
                margin: 0 auto;
            }
        }
    }

    .filters_block {
        display: none !important;
    }

    .filter_block {
        position: relative;

        &.items {
            .global_menu_block {
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-content: center;
                padding: 0;
                margin: 0 auto;
                min-height: 45px;
                list-style: none;
                align-items: center;
                background-color: $white-gray;

                @include max-screen(1200px) {
                    align-items: flex-start;
                }

                .btn-default-1 {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                }

                li {
                    position: relative;
                    z-index: 99;
                    padding: 15px 20px 15px 25px;
                    margin: 0;
                    width: 100%;
                    height: 50px;
                    border-right: 1px solid $border-color;
                    border-bottom: 1px solid transparent;
                    cursor: pointer;
                    color: $blue;

                    @include max-screen(1200px) {
                        border-right: none;
                    }

                    @include max-screen(768px) {
                        padding: 15px 20px 15px 15px;
                    }

                    @include all-trans-ease(.5s);

                    &:last-child {
                        min-width: 25%;
                    }

                    a {
                        color: $blue;
                    }

                    &:hover {
                        a span {
                            color: $light-blue;
                        }
                    }
                }

                .li-catalog {
                    position: relative;
                    top: 0;
                    width: 100%;
                    max-width: 272px;
                    border-left: 1px solid $border-color;

                    @include max-screen(1200px) {
                        width: 50%;
                        max-width: 100%;
                    }

                    .sub-menuCatalog {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    &:hover {
                        &::after {
                            color: $light-blue;
                        }
                    }

                    &::after {
                        display: inline-block;
                        position: absolute;
                        right: 25px;
                        top: 15px;
                        cursor: pointer;
                    }

                    .menu-icon {
                        width: 20px;
                        height: 12px;
                        position: relative;
                        cursor: pointer;
                        margin: 0;

                        span {
                            display: block;
                            position: absolute;
                            height: 2px;
                            width: 100%;
                            background: $blue;
                            border-radius: 0;
                            opacity: 1;
                            left: 0;
                            transform: rotate(0deg);
                            transition: .3s ease-in-out;

                            &:nth-child(1) {
                                top: 0;
                            }

                            &:nth-child(2),
                            &:nth-child(3) {
                                top: 5px;
                            }

                            &:nth-child(4) {
                                top: 10px;
                            }
                        }

                        &.active {
                            span {
                                &:nth-child(1) {
                                    width: 0;
                                    top: 18px;
                                    left: 50%;
                                }

                                &:nth-child(2) {
                                    transform: rotate(45deg);
                                }

                                &:nth-child(3) {
                                    transform: rotate(-45deg);
                                }

                                &:nth-child(4) {
                                    width: 0;
                                    top: 18px;
                                    left: 50%;
                                }
                            }
                        }
                    }
                }

                .li-sezon,
                .li-razmer,
                .li-cena,
                .li-sort {
                    width: 100%;
                    position: relative;
                    border-top: 1px solid transparent;

                    @include max-screen(1200px) {
                        width: 50%;
                        display: flex;
                        flex-direction: column;
                        height: auto;
                        padding: 0;
                        border-top: none;
                    }

                    @include max-screen(1024px) {
                    }

                    &:hover {
                        &::after {
                            color: $light-blue;
                        }
                    }

                    & > a {
                        @include max-screen(1200px) {
                            padding: 15px 10px 15px 25px;
                            border: 1px solid $border-color;
                            border-top: none;
                        }


                        @include max-screen(1024px) {
                            padding: 15px;
                        }
                    }

                    & > [class^="li-"] {
                        display: none;
                        position: absolute;
                        margin: 0 auto;
                        top: 50px;
                        left: -1px;

                        @include max-screen(1200px) {
                            position: relative;
                            top: 0;
                            left: 0;
                        }

                        .block-range {
                            display: flex;
                            flex-direction: column;
                            margin: 0;
                            height: auto;

                            @include max-screen(1200px) {
                                width: 100%;
                            }

                            .box {
                                &.relation_block {
                                    background-color: $white-gray;
                                    padding: 0;

                                    .row {
                                        margin: 0;
                                    }

                                    .form-group {
                                        padding: 0;
                                        margin: 0;
                                        display: flex;
                                        width: 100%;
                                        height: 100%;

                                        & > div {
                                            width: 100%;
                                            padding: 0;
                                            border-bottom: 1px solid $border-color;
                                        }

                                        &:last-child {
                                            & > div {
                                                border-bottom: none;
                                            }
                                        }

                                        .checkbox {
                                            position: relative;
                                            padding-left: 0;

                                            label {
                                                display: flex;
                                                justify-content: flex-start;
                                                align-items: center;
                                                position: relative;
                                                top: 0;
                                                right: 0;
                                                bottom: 0;
                                                left: 0;
                                                padding: 15px 10px 15px 25px;
                                                margin: 0;

                                                &::before {
                                                    content: '';
                                                    position: absolute;
                                                    top: 50%;
                                                    transform: translateY(-50%);
                                                    right: 0;
                                                    bottom: 0;
                                                    left: 25px;
                                                    max-width: 15px;
                                                    max-height: 15px;
                                                    border: 1px solid $border-color;

                                                    @include all-trans-ease(.5s);
                                                }

                                                &::after {
                                                    content: '';
                                                    position: absolute;
                                                    top: calc(50% - 7px);
                                                    right: 0;
                                                    bottom: 0;
                                                    left: 31px;
                                                    max-width: 18px;
                                                    max-height: 8px;
                                                    border-left: 1px solid $black;
                                                    border-bottom: 1px solid $black;
                                                    opacity: 0;

                                                    transform: rotate(-45deg) translateY(-50%);

                                                    @include all-trans-ease(.5s);
                                                }

                                                &.active {
                                                    &::before {
                                                        border: 1px solid $black;
                                                    }

                                                    &::after {
                                                        opacity: 1;
                                                    }
                                                }
                                            }

                                            input {
                                                opacity: 0;
                                                margin: 0;
                                            }

                                            span {
                                                padding-left: 15px;
                                            }
                                        }
                                    }
                                }

                                &.price_block {
                                    padding: 0;

                                    .row {
                                        margin: 0;

                                        & > div {
                                            padding: 0;

                                            &:last-child {
                                                .input-group {
                                                    border: none;
                                                }
                                            }
                                        }
                                    }

                                    .input-group {
                                        margin: 0;
                                        border-bottom: 1px solid $border-color;

                                        .input-group-addon,
                                        input {
                                            height: auto;
                                            border: none;
                                            box-shadow: none;
                                            font-family: $font_m;
                                            font-weight: 600;
                                            font-style: normal;
                                            font-size: 13px;
                                            background-color: $white-gray;
                                        }

                                        .input-group-addon {
                                            min-width: 60px;
                                            position: relative;
                                            padding: 15px 15px 15px 25px;

                                            &::before {
                                                content: '';
                                                position: absolute;
                                                top: 10px;
                                                right: 0;
                                                bottom: 10px;
                                                left: 0;
                                                border-right: 1px solid $border-color;
                                            }
                                        }

                                        input {
                                            padding: 15px;
                                        }
                                    }
                                }

                                &.padding-box {
                                    display: flex;
                                    padding: 0;
                                    position: relative;

                                    &::before {
                                        position: absolute;
                                        content: '';
                                        top: -1px;
                                        right: -1px;
                                        bottom: -1px;
                                        left: -1px;
                                        background-color: $filter-button;
                                    }

                                    input {
                                        position: inherit;
                                        text-transform: none;
                                        border: none;
                                        background-color: $filter-button;
                                    }
                                }
                            }
                        }
                    }

                    [class^="toggle_list_"] {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;

                        @include arrow-toggle;
                    }
                }

                .li-sezon,
                .li-razmer {
                    max-width: 140px;
                    min-width: 140px;

                    @include max-screen(1200px) {
                        max-width: 100%;
                        min-width: 100%;
                    }

                    & > [class^="li-"] {
                        width: 141px;

                        @include min-screen(1200px) {
                            @include shadow-filter;
                        }

                        @include max-screen(1200px) {
                            width: 100%;
                        }
                    }
                }

                .li-cena {
                    max-width: 270px;
                    min-width: 270px !important;

                    @include max-screen(1200px) {
                        max-width: 100%;
                        min-width: 100% !important;
                    }

                    .li-price-item_lines {
                        width: 271px;

                        @include min-screen(1200px) {
                            @include shadow-filter;
                        }

                        @include max-screen(1200px) {
                            width: 100%;
                        }
                    }

                    & > a {
                        .title {
                            padding: 0 10px;
                        }

                        @include max-screen(768px) {
                            display: flex;
                            flex-wrap: wrap;

                            .title {
                                padding: 0;
                            }

                            span:first-child {
                                width: 100%;
                                padding-bottom: 10px;
                            }

                            span:nth-child(2),
                            span:nth-child(4) {
                                width: 20%;
                            }

                            span:nth-child(3),
                            span:nth-child(5) {
                                width: 80%;
                            }
                        }
                    }
                }

                .li-sort {
                    min-width: 40% !important;
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    @include max-screen(1200px) {
                        width: 100%;
                        border: 1px solid $border-color;
                        border-top: none;
                    }

                    @include max-screen(1024px) {
                        align-items: flex-start;
                    }

                    a {
                        width: 55%;

                        @include max-screen(1200px) {
                            border: none;
                        }

                        @include max-screen(768px) {
                            span:first-child {
                                font-size: 11px;
                            }
                        }

                        & > [class^="toggle_list_"] {
                            @include arrow-toggle;
                        }
                    }

                    & > .li-sort-item_lines {
                        display: none;
                        width: calc(100% + 2px);
                        background-color: $white-gray;
                        border-top: 0;

                        @include min-screen(1200px) {
                            @include shadow-filter;
                        }

                        @include max-screen(1200px) {
                            width: 100%;
                            border: none;
                            border-top: 1px solid $border-color;
                        }

                        span {
                            font-family: $font_m;
                            font-weight: 600;
                            font-style: normal;
                            font-size: 13px;
                            padding: 15px 15px 15px 25px;
                            border-bottom: 1px solid $border-color;

                            -webkit-transition: color .5s ease;
                            -moz-transition: color .5s ease;
                            -ms-transition: color .5s ease;
                            -o-transition: color .5s ease;
                            transition: color .5s ease;

                            &:last-child {
                                border: none;
                            }

                            &:hover {
                                color: $light-blue;
                            }
                        }

                        .block-range {
                            padding: 1px 0;

                            @include max-screen(1200px) {
                                border: none;
                            }
                        }
                    }

                    .sort-control {
                        height: auto;
                    }
                }

                select {
                    width: 100%;
                    min-width: 170px;
                    display: inline;
                    position: relative;
                    z-index: 22;
                    padding: 0;
                    padding-right: 30px;
                    border: none;
                    background: transparent;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    direction: rtl;
                    pointer-events: none;
                    cursor: pointer;
                    box-shadow: none;

                    @include max-screen(1200px) {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        padding-right: 60px;
                    }

                    @include max-screen(1024px) {
                        box-sizing: border-box;
                        position: relative;
                        direction: ltr;
                        width: 100%;
                        min-width: inherit;
                        padding: 0;
                        padding-left: 15px;
                        font-size: 12px;
                    }

                    &:focus {
                        background-color: transparent !important;
                    }

                    option {
                        background-color: $white-gray;
                    }
                }

                .li-filter-button {
                    display: none;
                    border-right: 1px solid $border-color;
                    border-left: 1px solid $border-color;

                    & > a {
                        width: 100%;
                        display: flex;
                        -ms-flex-pack: justify;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .menu-icon {
                        width: 20px;
                        height: 12px;
                        position: relative;
                        cursor: pointer;
                        margin: 0;

                        span {
                            display: block;
                            position: absolute;
                            background: $blue;
                            opacity: 1;
                            left: 0;
                            transform: rotate(0deg);
                            transition: .3s ease-in-out;

                            width: 3px;
                            height: 3px;
                            border-radius: 50%;

                            &:nth-child(1) {
                                top: 0;
                                left: 15px;
                            }

                            &:nth-child(2) {
                                top: 0;
                            }

                            &:nth-child(3) {
                                top: 10px;
                            }

                            &:nth-child(4) {
                                top: 10px;
                                left: 15px;
                            }
                        }

                        &.active {
                            span {
                                width: 100%;
                                height: 2px;
                                border-radius: 0;

                                &:nth-child(1) {
                                    left: 0;
                                    top: 5px;
                                    transform: rotate(-45deg);
                                }

                                &:nth-child(2) {
                                    top: 5px;
                                    left: 0;
                                    transform: rotate(45deg);
                                }

                                &:nth-child(3) {
                                    top: 5px;
                                    transform: rotate(-45deg);
                                }

                                &:nth-child(4) {
                                    left: 0;
                                    top: 5px;
                                    transform: rotate(45deg);
                                }
                            }
                        }
                    }

                    @include max-screen(1200px) {
                        display: flex;
                    }
                }

                .li-filter {
                    display: flex;

                    @include max-screen(1200px) {
                        width: 50%;
                        flex-direction: column;
                    }
                }

                .li-wrapper {
                    display: flex;

                    @include max-screen(1200px) {
                        display: none;
                        position: absolute;
                        top: 51px;
                        z-index: 98;
                        padding: 0;
                        width: 100%;
                        max-width: 50%;
                        margin: 0;
                        border-top: none;
                        border-bottom: none;
                        list-style: none;
                        background-color: $white-gray;
                        box-sizing: border-box;
                    }

                    @include shadow-filter;
                }
            }

            .level1 {
                display: none;
                position: absolute;
                top: 51px;
                z-index: 98;
                padding: 0;
                margin-right: 20px;
                margin-bottom: 0;
                width: 100%;
                max-width: calc(25% - 21px);
                border-top: none;
                border-bottom: none;
                list-style: none;
                background-color: $white-gray;
                box-sizing: border-box;

                @include max-screen(1200px) {
                    width: 100%;
                    max-width: 50%;
                    margin: 0;
                }

                @include shadow-filter;

                li {
                    font-size: 13px;
                }

                .top-menu__item {
                    display: flex;
                    flex-direction: column;
                    list-style: none;
                    padding: 0;
                    cursor: pointer;

                    & > a {
                        position: relative;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 20px 25px;
                        border-bottom: 1px solid $border-color;
                        font-weight: 600;
                        pointer-events: none;

                        @include max-screen(768px) {
                            padding: 15px 30px 15px 15px;
                        }

                        &::after {
                            font-family: Flaticon;
                            content: "\f104";
                            font-size: 20px;
                            display: inline-block;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 20px;
                            cursor: pointer;

                            -webkit-transition: all .5s ease;
                            -moz-transition: all .5s ease;
                            -ms-transition: all .5s ease;
                            -o-transition: all .5s ease;
                            transition: all .5s ease;
                        }

                        &.active {
                            &::after {
                                transform: translateY(-50%) rotate(-180deg);
                            }
                        }
                    }

                    a {
                        text-decoration: none;
                    }

                    .top-sub-menu {
                        padding: 10px 0;
                        border-bottom: 1px solid $border-color;

                        .top-sub-menu__item {
                            list-style: none;
                            padding: 10px 10px 10px 50px;

                            @include max-screen(768px) {
                                padding: 10px 20px;
                            }
                        }
                    }

                    .sub-block {
                        display: none;
                    }
                }
            }
        }
    }

    &__one-product {
        .global_menu_block {
            justify-content: space-between !important;

            li {
                @include max-screen(1200px) {
                    border-right: 1px solid $border-color !important;
                }
            }

            .back-button {
                max-width: 285px;
                text-align: right;
                border-left: 1px solid $border-color;

                a {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    padding: 0 20px;
                    width: 100%;
                    text-transform: uppercase;
                    font-family: $font_m;
                    font-weight: 600;
                    font-style: normal;
                    font-size: 13px;
                }

                &::before {
                    font-family: Flaticon;
                    content: "\f104";
                    position: absolute;
                    top: 50%;
                    left: 25px;
                    font-size: 20px;
                    font-weight: 600;
                    cursor: pointer;
                    transition: all .5s ease;

                    transform: translateY(-50%) rotate(90deg);

                    @include max-screen(768px) {
                        display: none;
                    }
                }

                &:hover {
                    a {
                        text-decoration: none;
                        color: $light-blue !important;
                    }
                }
            }
        }

        .menu_and_filters_block {
            .filter_block_line {
                padding: 0 15px;

                @include max-screen(1024px) {
                    padding: 0;
                }
            }
        }

        .filter_block.items {
            .level1 {
                margin: 0;
                max-width: 25%;

                @include max-screen(1200px) {
                    width: 100%;
                    max-width: 285px !important;
                }

                @include max-screen(768px) {
                    width: 100%;
                    max-width: 50% !important;
                }
            }

            .global_menu_block {
                .li-catalog {
                    max-width: 285px;

                    @include max-screen(768px) {
                        max-width: 50%;
                    }
                }

                .back-button {
                    @include max-screen(768px) {
                        max-width: 50%;
                        border-left: none;
                    }
                }
            }
        }

        label {
            text-transform: uppercase;
            font-family: $font_m;
            font-weight: 600;
            font-style: normal;
            font-size: 13px !important;
        }

        .vibor_razmera {
            position: relative;
            padding: 25px 0;
            margin-left: 0;
            margin-bottom: 25px;
            border-bottom: 1px solid $border-color;

            .field {
                display: flex;
                flex-wrap: wrap;
                padding: 0;
                margin: 0;

                label {
                    width: 100%;
                    padding: 0;
                    margin: 0 0 25px;
                }

                & > .field-value {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    width: 100%;

                    .ref-link {
                        min-width: 150px;
                        text-align: center;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        top: -45px;
                        font-family: $font_m;
                        font-weight: 600;
                        font-style: normal;
                        font-size: 13px !important;
                        color: $blue;

                        -webkit-transition: color .5s ease;
                        -moz-transition: color .5s ease;
                        -ms-transition: color .5s ease;
                        -o-transition: color .5s ease;
                        transition: color .5s ease;

                        &__text {
                            display: none;

                            &.active {
                                display: block;
                            }
                        }

                        &:hover {
                            color: $light-blue;
                        }
                    }
                }
            }
        }
    }
}